import React, {Component} from 'react';
import tall from '../../assets/125140427_3332318966895608_2045044488942562908_n.jpg';
import bild2 from '../../assets/services/wash/124279492_666954673965755_7355991074929729333_n-768x1024.jpg'
import bild3 from '../../assets/DSC_0005.jpg'
import bild4 from '../../assets/20200831_150126-1024x768kopia.jpg'
import concrete from '../../assets/triangular-shovel-with-liquid-concrete.png';
import water from '../../assets/126363-200.png';
import spray from '../../assets/8-512.png';
import wash from '../../assets/pressure-washer.png';
import rust from '../../assets/waterproof_water-repellent_resistant_water-resistant_weather_corrosion_protection_accessory-512.png';
import balcony from '../../assets/balcony.png';
import floor from '../../assets/house-renovation-worker-008-512.png';
import enginer from '../../assets/engineer.png';
import man from '../../assets/noimage.jpg';
import {motion} from 'framer-motion';
import arrow from '../../assets/arrow-down-sign-to-navigate.png'

const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const story = {
    intro:{
        smallTitle: 'Specialister',
        title: 'Korrisions säkra',
        text: 'Etablera era fastigheter med ett fräscht utseende och funktion med Nixco\'s tjänster. Från gjutningar och betongreprationer till tak behandlingar, ni kan förvänta er en hög standard på era industri eller fastighets renoveringar.',
    },
    about: {
        smallTitle: 'Om Nixco',
        title: 'Vi höjer era fastighet\'s funktion',
        text: 'Hos oss får du alltid dina krav på kvalitet uppfyllda. Vår kvalitetsgaranti och vårt förbättringsprogram är en viktig del i arbetet att ständigt utveckla och förbättra vårt löfte till dig som kund.',
    },
    services: {
        smallTitle: 'Tjänster',
        title: 'Våra Tjänster',
        text: 'Nixco erbjuder olika tjänster inom fastigheter och industrilokaler, här är några av våra tjänster vi erbjuder',
    },
    workers: {
        smallTitle: 'Arbetare',
        title: 'Våra Specialister',
        text: 'Vi anställer personal med hög erfarenhet som har hög passion för våra tjänster',
    },
};

const services = [
    {
        img: concrete,
        title: 'Gjutningar och betongreprasioner',
        text: 'Allt inom betong gjutningar och reprationer, små och stora project',
        link:'/services/concrete'
    },
    {
        img: water,
        title: 'Vattenbilning',
        text: 'Vi erbjuder vattenbilning och vatten fräsning av betong med modern och säker utrustning',
        link:'/services/watercrush'
    },
    {
        img: spray,
        title: 'Sprutmålning',
        text: 'Högtrycks sprutmålning av fasader, tak och stora lokaler',
        link:'/services/spraypaint'
    },
    {
        img: wash,
        title: 'Fasadtvätt',
        text: 'Våra högtrycks sprutor garanterar ett bra jobb utan kemikalie inblandning',
        link:'/services/wash'
    },
    {
        img: rust,
        title: 'Korrosionsbehandling',
        text: 'Vi är specialister på korrosionsbehandla stål konstruktioner',
        link:'/services/corrosion'
    },
    {
        img: balcony,
        title: 'Balkongrenovering',
        text: 'Vår balkong service ger fasaden ett lyft och säkerställer en god funktion',
        link:'/services/balcony'
    },
    {
        img: floor,
        title: 'Industrigolv',
        text: 'Vi har behandlingar av alla typer av industrigolv',
        link:'/services/floor'
    },
];

const workers = [
    {
        img: man,
        name: 'Jonas Cohen',
        description: 'Grundare och VD för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    {
        img: man,
        name: 'Marcus Cohen',
        description: 'Produktions Ansvarig',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    /*{
        img: wide,
        name: 'Keith Fast',
        description: 'Medarbetare för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    {
        img: wide,
        name: 'Emil Fransson',
        description: 'Medarbetare för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },*/
];

class Home extends Component {

    constructor(props) {
        super(props);
        this.state={
            yposition: 0,
            mounted: false,
            top: false,
            about: false,
            services: false,
            workers: false,
            containersPosition: [],
            ypositionBottom: 0
        };
        this.serviceContaier = React.createRef();
        this.topContainer = React.createRef();
        this.workerContainer = React.createRef();
        this.aboutContainer = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //console.log(this.props)
        document.addEventListener('scroll', this.handleScroll);
        let height = window.innerHeight;
        let containersPosition = [
            this.topContainer.current.offsetTop,// - this.topContainer.current.clientHeight,
            this.aboutContainer.current.offsetTop,// - this.aboutContainer.current.clientHeight,
            this.serviceContaier.current.offsetTop,// - this.serviceContaier.current.clientHeight,
            this.workerContainer.current.offsetTop,// - this.workerContainer.current.clientHeight
        ];
        this.setState({
            mounted: true,
            yposition: window.scrollY,
            containersPosition: containersPosition,
            ypositionBottom: window.scrollY + height
        });
        //console.log('hej',this.state.ypositionBottom, containersPosition);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.scrollTo !== ''){
            if(nextProps.scrollTo === 'workers'){
                window.scrollTo({
                    top:nextState.containersPosition[3],
                    left: 0,
                    behavior: 'smooth'
                });
            }else if(nextProps.scrollTo === 'services'){
                window.scrollTo({
                    top:nextState.containersPosition[2],
                    left: 0,
                    behavior: 'smooth'
                });
            }
            this.props.scrollToFunc('');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevState.about){
            if(prevState.containersPosition[1] < prevState.ypositionBottom-200){
                this.setState({
                    about: true
                })
            }
        }
        if(!prevState.top){
            if(prevState.containersPosition[0] < prevState.ypositionBottom-200){
                this.setState({
                    top: true
                })
            }
        }
        if(!prevState.services){
            if(prevState.containersPosition[2] < prevState.ypositionBottom-200){
                this.setState({
                    services: true
                })
            }
        }
        if(!prevState.workers){
            if(prevState.containersPosition[3] < prevState.ypositionBottom-200){
                this.setState({
                    workers: true
                })
            }
        }
    }

    workersGridString = (array) => {
        let str = '';
        let length = array.length > 2 ? 3 : array.length;
        if(window.innerWidth < 1000){
            length = 1;
        }
        for(let i = 0; i < length; i++){
            str += 'auto ';
        }
        return str;
    };

    handleScroll = (event) =>{
        //console.log(window.scrollY, this.state.ypositionBottom, this.state.containersPosition);
        // this.forceUpdate()
        this.setState({
            yposition: window.scrollY,
            ypositionBottom: window.scrollY + window.innerHeight
        })
    };

    onPan = (event) =>{
        //onsole.log(event);
    };

    render(){
        let scrollY = this.state.yposition;
        let contPos = this.state.containersPosition;

        return (
            <div style={{display: 'flex', flexDirection: 'column', width:'100%'}}>
                <div style={{width: '100%', height: '100vh', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white',}}
                     ref={this.topContainer}
                >

                    {this.state.mounted ?(<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>
                            <motion.div initial={{scale: 1.5, opacity: 0}}
                                        animate={{scale: 1, height: '100%', opacity: 1}}
                                        transition={{
                                            duration: 1,
                                            layoutX: { duration: 0.3 },
                                            layoutY: { duration: 0.3 },
                                        }}
                                        variants={variants}

                                        style={{overflow: 'hidden', width: '100%', height: '100%',position: 'absolute', top: this.topContainer.current ? (this.topContainer.current.clientHeight > scrollY ? scrollY/2 : 0) : 0, left:0, zIndex: 0}}
                            >
                                <div style={{backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}/>
                                <img src={tall} style={{minHeight: '100%', minWidth: '100%',filter: 'grayscale(100%)', position: 'absolute', top: 0, left: 0, zIndex: -2}} alt={'Bakgrundsbild tak'}/>
                            </motion.div>
                            <div style={{color: 'rgb(255,225,27)', textAlign: 'center',paddingTop: '30%', maxWidth: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 2}}>

                                <motion.div style={{marginBottom: scrollY/30}}
                                            initial={{filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                                            animate={{filter: 'blur(0px)', scale: 1, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    <img src={enginer} style={{width: '10%', filter: 'invert(98%) sepia(27%) saturate(7489%) hue-rotate(336deg) brightness(112%) contrast(104%)'}}/>
                                </motion.div>
                                <motion.div style={{marginBottom: scrollY/30, fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 16, fontWeight: 400, letterSpacing: 8, marginTop: 30}}
                                            initial={{filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                                            animate={{filter: 'blur(0px)', scale: 1, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    {story.intro.smallTitle}

                                </motion.div>
                                <motion.div style={{color: 'white', marginBottom: scrollY/30, fontFamily: '\'Ultra\', serif', fontSize: 60, marginTop: 15, width: '70%', lineHeight: 1}}
                                            initial={{filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                                            animate={{filter: 'blur(0px)', scale: 1, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    {story.intro.title}

                                </motion.div>
                                <motion.div style={{marginBottom: scrollY/30, backgroundColor: 'rgb(255,225,27)', width: '20%', height: 5,marginTop: 30, zIndex: 3}}
                                            initial={{width: 0}}
                                            animate={{width: '40%'}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'anticipate',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                />
                                <motion.div style={{color: 'rgb(230,230,230)', fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200, marginTop: 30, marginBottom: '40%', zIndex: 3, width: '80%', alignSelf: 'center'}}
                                            initial={{y: 100, opacity: 0}}
                                            animate={{y: 0, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    {story.intro.text}
                                </motion.div>
                                <motion.div style={{position: 'absolute', bottom: 50, cursor: 'pointer'}}
                                            whileHover={{scale: 1.2}}
                                            whileTap={{scale: 0.9}}
                                            onClick={()=>{
                                                window.scrollTo({
                                                    top:this.state.containersPosition[1],
                                                    left: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}
                                >
                                    <img src={arrow} style={{width: 50, filter: 'invert(98%) sepia(27%) saturate(7489%) hue-rotate(336deg) brightness(112%) contrast(104%)'}}/>
                                </motion.div>
                            </div>
                        </div>
                    ):null}
                </div>
                <div style={{zIndex: 2, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', alignSelf: 'center', minHeight: '70vh'}}
                     ref={this.aboutContainer}
                >
                    <div style={{display: 'flex', flexDirection: 'row', maxWidth: 1200, height: this.aboutContainer.current ? this.aboutContainer.current.clientHeight * 0.8 : '100%'}}>
                        <div style={{width: '50%', height: '100%', overflow: 'hidden'}}>
                            <img src={bild2} style={{height: '100%', filter: 'grayscale(100%) brightness(110%)'}}/>
                        </div>
                        <div style={{width: '50%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row',alignItems: 'center', justifyContent: 'flex-end',  width: '80%', marginTop: 40, fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 16, fontWeight: 300, letterSpacing: 4, position: 'relative', zIndex: 5, whiteSpace: 'nowrap'}}>
                                {story.about.smallTitle}
                                <div style={{width: '80%', height: '50%', backgroundColor: 'rgb(255,225,27)', marginTop: 3, marginLeft: 10}}/>
                                <motion.div
                                    //initial={contPos[1] < scrollY ? {width: '100%'} : false}
                                    animate={this.state.about ? 'inView' : 'outView'}
                                    variants={{
                                        inView:{width: '0%'},
                                        outView:{width: '100%'}
                                    }}

                                    transition={{
                                        ease: 'linear',
                                        duration: 1
                                    }}
                                    style={{backgroundColor: 'white', width: '100%', height: '100%', zIndex: 5, position: 'absolute', top: 0, right: 0}}
                                />


                            </div>
                            <div style={{position: 'relative', color: 'white', fontFamily: '\'Ultra\', serif', fontSize: 60, marginTop: 60, textTransform: 'capitalize',textAlign: 'left'}}>
                                <div style={{userSelect: 'none'}}>
                                    {story.about.title}
                                </div>
                                <motion.div style={{position: 'absolute', top: 0, left: '-20%', color: 'black', width: '100%', height: '100%'}}
                                    //initial={{x: 100, filter: 'blur(10px)', opacity: 0}}
                                            animate={this.state.about ? 'inView' : 'outView'}
                                            variants={{
                                                outView:{x: 0, filter: 'blur(10px)', opacity: 0},
                                                inView:{x: 0, filter: 'blur(0px)', opacity: 1}
                                            }}
                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                            }}
                                >
                                    {story.about.title}
                                </motion.div>
                            </div>
                            <motion.div style={{marginTop: 60, color: 'rgb(100,100,100)', fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200, marginBottom: 80, width: '80%', lineHeight: 1.5, textAlign: 'left'}}
                                //initial={{y: 100, opacity: 0}}
                                        animate={this.state.about ? 'inView' : 'outView'}
                                        variants={{
                                            inView: {y: 0, opacity: 1},
                                            outView: {y: 0, opacity: 0}
                                        }}
                                        transition={{
                                            ease: 'easeOut',
                                            duration: 1,
                                        }}
                            >
                                {story.about.text}
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div style={{overflow: 'hidden',display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', alignItems: 'center'}}
                     ref={this.serviceContaier}
                >
                    <motion.div style={{ position: 'absolute', top: this.serviceContaier.current ? (this.serviceContaier.current.clientHeight + this.serviceContaier.current.offsetTop > scrollY ? ((scrollY-this.serviceContaier.current.offsetTop)/2):0)  : 0, left: 0, width: '100%', zIndex: -2, height: this.serviceContaier.current ? this.serviceContaier.current.clientHeight*1.1 : '100%'}}
                        //initial={{scale: 1.5, filter: 'blur(10px)'}}
                                animate={this.state.services ? 'inView' : 'outView'}
                                variants={{
                                    inView:{scale: 1, filter: 'blur(0px)'},
                                    outView:{scale: 1.5, filter: 'blur(10px)'}
                                }}
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1
                                }}
                    >
                        <div style={{backgroundColor: 'rgba(0,0,0,0.8)', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}/>
                        <img src={bild3} style={{filter: 'grayscale(100%)', minHeight: '100%', minWidth: '100%', position: 'absolute', top: 0, left: 0, zIndex: -4}}/>
                    </motion.div>
                    <div style={{color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', maxWidth: 1200}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>


                            <motion.div style={{fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 16, fontWeight: 300, letterSpacing: 4, overflow: 'hidden',display: 'flex', flexDirection: 'row',width: '80%', marginTop: 40}}
                                        animate={this.state.services ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{width: '90%'},
                                            outView:{width: 0}
                                        }}
                                        transition={{
                                            duration: 2,
                                            ease: 'linear',
                                            //delay: 1
                                        }}
                            >
                                <div style={{width: '100%', backgroundColor: 'yellow', marginTop: 9, marginRight: 10, marginBottom: 6}}/>
                                {story.services.smallTitle}
                                <div style={{width: '100%', backgroundColor: 'yellow', marginTop: 9, marginLeft: 10, marginBottom: 6}}/>
                            </motion.div>

                            <motion.div style={{marginTop: 30, fontFamily: '\'Ultra\', serif', fontSize: 60}}
                                //initial={{y: -40, opacity: 0, filter: 'blur(10px)'}}
                                        animate={this.state.services ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{y:0, opacity: 1, filter: 'blur(0px)'},
                                            outView:{y: -40, opacity: 0, filter: 'blur(10px)'}
                                        }}
                                        transition={{
                                            duration: 1,
                                            //delay: 1,
                                            ease: 'easeOut'
                                        }}
                            >
                                {story.services.title}
                            </motion.div>
                            <motion.div style={{color: 'rgb(230,230,230)', marginTop: 30, fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200, marginBottom: 80, lineHeight: 1.5, width: '50%'}}
                                //initial={{y: 40, opacity: 0}}
                                        animate={this.state.services ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{y:0, opacity: 1},
                                            outView:{y: 40, opacity: 0}
                                        }}
                                        transition={{
                                            duration: 1,
                                            //delay: 1,
                                            ease: 'anticipate'
                                        }}
                            >
                                {story.services.text}
                            </motion.div>
                        </div>
                        <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto', flexDirection: 'row', width: '80%', height: '100%', justifyItems: 'center', alignItems: 'center'}}>
                            {services.map((service, i)=>{
                                return (
                                    <div style={{transition: 'all .2s ease-in-out', cursor: 'pointer', gridColumnStart: i === services.length - 1 ? 2 : 0, display: 'flex', flexDirection: 'column', maxWidth: '80%', alignSelf: 'center', alignItems: 'center'}}
                                        onClick={()=>{
                                         //console.log('hrj')
                                         this.props.history.push(service.link);
                                     }}
                                         onPointerEnter={(e)=>{
                                                     e.currentTarget.style.transform = 'scale(1.2)';
                                                     //e.currentTarget.style.width = '250px';
                                                 }}
                                                 onPointerLeave={(e)=>{
                                                     e.currentTarget.style.transform = 'scale(1)';
                                                 }}
                                    >
                                        <motion.div style={{width: '100%', height: '100%', overflow: 'hidden'}}
                                            //initial={{width: '30%', y: 50, opacity: 0}}
                                                    animate={this.state.services ? 'inView' : 'outView'}
                                                    variants={{
                                                        inView:{width: '100%', y:0, opacity: 1},
                                                        outView:{width: '30%', y: 50, opacity: 0}
                                                    }}
                                                    transition={{
                                                        duration: .8,
                                                        ease: 'linear'
                                                    }}
                                        >
                                            <img src={service.img} style={{filter: 'invert(98%) sepia(27%) saturate(7489%) hue-rotate(336deg) brightness(112%) contrast(104%)', width: '30%', height: '100%'}}/>
                                        </motion.div>
                                        <motion.div style={{marginTop: 15, fontFamily: '\'Oswald\', sans-serif', fontSize: 20, fontWeight: 600, textTransform: 'uppercase'}}
                                            //initial={{y: 50, opacity: 0}}
                                                    animate={this.state.services ? 'inView' : 'outView'}
                                                    variants={{
                                                        inView:{y: 0, opacity: 1},
                                                        outView:{y: 50, opacity: 0}
                                                    }}
                                                    transition={{
                                                        duration: 1,
                                                        ease: 'anticipate',
                                                        delay: .5
                                                    }}
                                        >
                                            {service.title}
                                        </motion.div>
                                        <motion.div style={{marginTop: 15, color: 'rgb(230,230,230)', fontFamily: '\'Roboto Slab\', serif', fontSize: 18, fontWeight: 100, marginBottom: 50}}
                                            //initial={{y: 50, opacity: 0}}
                                                    animate={this.state.services ? 'inView' : 'outView'}
                                                    variants={{
                                                        inView:{y: 0, opacity: 1},
                                                        outView:{y: 50, opacity: 0}
                                                    }}
                                                    transition={{
                                                        duration: 1,
                                                        ease: 'anticipate',
                                                        delay:.8
                                                    }}
                                        >
                                            {service.text}
                                        </motion.div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor: 'white',  width: '100%', color: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', position: 'relative', overflow: 'hidden', maxWidth: 1200, alignSelf: 'center'}}
                     ref={this.workerContainer}
                >
                    <div style={{position: 'absolute', top: 50, left: 0, width: '40%', display: 'flex', flexDirection: 'row',fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 16, fontWeight: 300, letterSpacing: 4}}>
                        <div style={{backgroundColor: 'rgb(255,225,27)', width: '100%', marginTop: 8, marginBottom: 6, marginRight: 10}}/>

                        {story.workers.smallTitle}
                        <motion.div
                            //initial={{width: '100%'}}
                            animate={this.state.workers ? 'inView' : 'outView'}
                            variants={{
                                inView:{width: '0%'},
                                outView:{width: '100%'}
                            }}
                            transition={{
                                ease: 'linear',
                                duration: 1
                            }}
                            style={{backgroundColor: 'white', width: '100%', height: '100%', zIndex: 5, position: 'absolute', top: 0, right: 0}}
                        />

                    </div>
                    <div style={{position: 'absolute', top: 0, left: 0, width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '50%', display: 'flex'}}/>
                        <div style={{width: '50%', position: 'relative', overflow: 'hidden'}}>
                            <div style={{backgroundColor: 'rgba(255,225,27,0.8)', width: '100%', height: '100%',position: 'absolute', top:0, left: 0, }}/>
                            <img src={bild4} style={{width: '100%', zIndex: -2}}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 3, width: '50%', marginTop: 80}}>

                        <motion.div style={{marginTop: 20, fontFamily: '\'Ultra\', serif', fontSize: 60}}
                            //initial={{x: -200, opacity: 0}}
                                    animate={this.state.workers ? 'inView' : 'outView'}
                                    variants={{
                                        inView:{x: 0, opacity: 1},
                                        outView:{x: 0, opacity: 0}
                                    }}
                                    transition={{
                                        ease: 'easeIn',
                                        duration: 0.8
                                    }}
                        >
                            {story.workers.title}
                        </motion.div>
                        <motion.div style={{marginTop: 30, color: 'rgb(100,100,100)', fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200, marginBottom: 70, paddingLeft: 20, paddingRight: 20, lineHeight: 1.5}}
                            //initial={{x: 200, opacity: 0}}
                                    animate={this.state.workers ? 'inView' : 'outView'}
                                    variants={{
                                        inView:{x: 0, opacity: 1},
                                        outView:{x: 0, opacity: 0}
                                    }}
                                    transition={{
                                        ease: 'easeIn',
                                        duration: 0.8
                                    }}
                        >
                            {story.workers.text}
                        </motion.div>
                    </div>
                    <div style={{width: '80%', alignSelf: 'center'}}>
                        <div style={{display: 'grid', gridTemplateColumns: this.workersGridString(workers), flexDirection: 'row', width: '100%', height: '100%', justifyItems: 'center', alignItems: 'center', zIndex: 4}}>
                            {workers.map((worker)=>{
                                return (
                                    <div style={{display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center', alignItems: 'center', zIndex: 5}}>
                                        <div style={{position: 'relative', width: '100%'}}
                                        >
                                            <img src={worker.img} style={{filter: 'grayscale(100%)', width: '40%', minWidth: 300}}/>
                                            <motion.div
                                                //initial={{width: '100%'}}
                                                animate={this.state.workers ? 'inView' : 'outView'}
                                                variants={{
                                                    inView:{width: '0%'},
                                                    outView:{width: '100%'}
                                                }}
                                                transition={{
                                                    ease: 'easeIn',
                                                    duration: 1
                                                }}
                                                style={{backgroundColor: 'white', width: '100%', height: '100%', zIndex: 5, position: 'absolute', top: 0, right: 0}}
                                            />
                                        </div>
                                        <motion.div style={{marginTop: 15, fontFamily: '\'Oswald\', sans-serif', fontSize: 18, fontWeight: 600, textTransform: 'uppercase'}}
                                            //initial={{opacity: 0, x: -50}}
                                                    animate={this.state.workers ? 'inView' : 'outView'}
                                                    variants={{
                                                        inView:{opacity: 1, x: 0},
                                                        outView:{opacity: 0, x: 0}
                                                    }}
                                                    transition={{
                                                        duration: 1,
                                                        ease: 'easeOut',
                                                        //delay: 1
                                                    }}
                                        >
                                            {worker.name}
                                        </motion.div>
                                        <motion.div style={{marginTop: 10, color: 'rgb(50,50,50)', fontFamily: '\'Roboto Slab\', serif', fontSize: 16, fontWeight: 200, marginBottom: 80, }}
                                            //initial={{opacity: 0, x: 50}}
                                                    animate={this.state.workers ? 'inView' : 'outView'}
                                                    variants={{
                                                        inView:{opacity: 1, x: 0},
                                                        outView:{opacity: 0, x: 0}
                                                    }}
                                                    transition={{
                                                        duration: 1,
                                                        ease: 'easeOut',
                                                        //delay: 1
                                                    }}
                                        >
                                            {worker.description}
                                        </motion.div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// this.serviceContaier.current.clientHeight this.serviceContaier.current.offsetTop - (scrollY )
export default Home;
