import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

const sidebar = {
    open: (height = 1000) => ({

        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        backgroundColor: '#ffff1b',
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {

        clipPath: "circle(20px at 25px 25px)",
        backgroundColor: '',
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40,
        }
    }
};

const show = {
    open: {
        display: ''
    },
    closed: {
        display: 'none'
    }
};

const show2 = {
    open: {
        width: '100%',
        height: '100%',
        minHeight: '100vh'
    },
    closed: {
        width: 50,
        height: 50,
        minHeight: '0%'
    }
};

const Menu = ({props,desktop}) => {
    //console.log(props);
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);
    return (
        <motion.div variants={show2} animate={isOpen ? "open" : "closed"} style={{overflow: 'hidden'}}>
            <motion.nav
                initial={false}
                variants={show2}
                animate={isOpen ? "open" : "closed"}
                custom={height}
                ref={containerRef}
                style={{zIndex: 10}}
                onClick={()=>{
                    if(isOpen)
                        toggleOpen()
                    //console.log('close')
                }}
            >
                <motion.div variants={sidebar}
                            animate={isOpen ? 'open' : 'closed'}
                            style={{position: 'fixed', width: 300, height: '100%', top: 0, left: 0, backgroundColor: 'yellow'}}
                            onClick={(e)=>{
                                if(isOpen) {
                                    //e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                >
                    <div style={{marginTop: 70,width: '100%', height: '100%', overflow: 'scroll'}}>
                        <Navigation props={props}/>
                    </div>
                </motion.div>
                <MenuToggle toggle={(e) => {
                    toggleOpen();
                    e.preventDefault();
                    e.stopPropagation();
                }} style={{zIndex:99999}}/>
            </motion.nav>
        </motion.div>
    );
};

export default Menu;

/*
<motion.div variants={show2} animate={isOpen ? "open" : "closed"} style={{overflow: 'hidden'}}>
        <motion.nav
            initial={false}
            variants={show2}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
            style={{zIndex: 10}}
        >
<motion.div variants={show} animate={isOpen ? 'open' : 'closed'}>
            <motion.div className="background" variants={sidebar} />
          </motion.div>
          <motion.div variants={show} animate={isOpen ? 'open' : 'closed'}>
            <Navigation props={props}/>
          </motion.div>

          <MenuToggle toggle={() => toggleOpen()} />
        </motion.nav>
      </motion.div>
 */
