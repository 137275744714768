import React, {Component} from 'react';
import GoogleMapReact from "google-map-react";
import man from '../assets/noimage.jpg';

const googleProps = {
    center: {
        lat: 57.78238272031859,
        lng: 14.255498283541135
    },
    zoom: 13
};

const AnyReactComponent = ({ text }) => (
    <div style={{display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        fontWeight: 500
    }}>
        <div style={{
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: 'red',
            marginBottom: 10
        }}/>
        {text}
    </div>);

const workers = [
    {
        img: man,
        name: 'Jonas Cohen',
        description: 'Grundare och VD',
        email: 'info@nixco.se',
        tel: '0731 - 80 60 87',
    },
    {
        img: man,
        name: 'Marcus Cohen',
        description: 'Produktions Ansvarig',
        email: 'marcus@nixco.se',
        tel: '0735 - 45 24 95',
    },
    /*{
        img: wide,
        name: 'Keith Fast',
        description: 'Medarbetare för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    {
        img: wide,
        name: 'Emil Fransson',
        description: 'Medarbetare för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },*/
];

class Contact extends Component {

    constructor(props) {
        super(props);

        this.aboutContainer = React.createRef();
    }

    render(){
        const {mobile} = this.props;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(100vh - 400px - 50px)',
                marginTop: 100,
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: 40,
                    fontFamily: '\'Oswald\', sans-serif',
                    textTransform: 'uppercase',
                    fontSize: 16,
                    fontWeight: 300,
                    letterSpacing: 4,
                    position: 'relative',
                    zIndex: 5,
                    whiteSpace: 'nowrap',
                    marginBottom: 50
                }}>
                    <div style={{
                        width: '100%',
                        backgroundColor: 'rgb(255,225,27)',
                        marginTop: 3,
                        marginLeft: '10%',
                        marginRight: 10,
                        height: 12,

                    }}/>
                    Kontakt
                    <div style={{
                        width: '100%',
                        backgroundColor: 'rgb(255,225,27)',
                        marginTop: 3,
                        marginLeft: 10,
                        marginRight: '10%',
                        height: 12
                    }}/>
                </div>
                <div style={{
                    width: '90%',
                    maxWidth: 1500,
                    minHeight: '50vh',
                    display: 'flex',
                    flexDirection: mobile ? 'column':'row',
                    justifyContent: 'space-between'
                }}>
                    {!mobile ? (
                        <div style={{
                            zIndex: 2,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: '50%',
                            marginBottom: 10}}
                             ref={this.aboutContainer}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                maxWidth: 1200,
                                height: this.aboutContainer.current ? this.aboutContainer.current.clientHeight * 0.8 : '100%',
                                width: '100%'
                            }}>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>

                                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                            <div style={{
                                                width: '90%',
                                                height: 500,
                                                overflow: 'hidden'
                                            }}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: 'AIzaSyBY9T5AKBvGlIRPetSw5lC4uIOI6iMbKQ4' }}
                                                    defaultCenter={googleProps.center}
                                                    defaultZoom={googleProps.zoom}
                                                >
                                                    <AnyReactComponent
                                                        lat={57.78228548132105}
                                                        lng={14.25548755470512}
                                                        text="Nixco"
                                                    />
                                                </GoogleMapReact>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>):null}
                    <div style={{width: mobile ? '100%':'50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <div style={{fontFamily: '\'Ultra\', serif',
                                fontSize: mobile ? 30:42}}>
                                Förfrågningar
                            </div>
                            <div style={{fontFamily: '\'Roboto Slab\', serif',
                                fontSize: mobile ? 20 : 24,
                                fontWeight: 200, marginTop: 30}}>
                                Kontakta oss för förfrågningar och referens jobb
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: mobile ? 'column':'row', width: '100%', marginTop: mobile ? 20 : 40}}>
                            {workers.map((worker)=>{
                                return (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: mobile ? '100%' : '80%',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            marginTop: 10,
                                            color: 'rgb(100,100,100)',
                                            fontFamily: '\'Roboto Slab\', serif',
                                            fontSize: 14,
                                            fontWeight: 200,
                                            marginBottom: mobile ? 5 :15,
                                        }}>
                                            {worker.description}
                                        </div>
                                        <div style={{
                                            width: mobile ? null : '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            margin: mobile ? 5 : 0
                                        }}>
                                            <img src={worker.img} style={{filter: 'grayscale(100%)', width: '100%'}}/>
                                            <div style={{display: 'flex', flexDirection:'column', alignSelf:'flex-start'}}>
                                                <div style={{
                                                    marginTop: 15,
                                                    fontFamily: '\'Oswald\', sans-serif',
                                                    fontSize: 16,
                                                    fontWeight: 600,
                                                    textTransform: 'uppercase'
                                                }}>
                                                    {worker.name}
                                                </div>
                                                <div style={{display: 'flex', flexDirection:'row', marginTop: mobile ? 5 : 10}}>
                                                    <div style={{
                                                        fontFamily: '\'Oswald\', sans-serif',
                                                        fontSize: 16,
                                                        fontWeight: 600,
                                                        textTransform: 'uppercase',
                                                        whiteSpace: 'pre'
                                                    }}>
                                                        {'Telfone:  '}
                                                    </div>
                                                    <div style={{
                                                        fontFamily: '\'Oswald\', sans-serif',
                                                        fontSize:  16,
                                                        fontWeight:  400,
                                                        textTransform: 'uppercase'
                                                    }}>
                                                        <a href={"tel:" + worker.tel} >{worker.tel}</a>
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', flexDirection:'row', marginTop: mobile ? 5 : 10}}>
                                                    <div style={{
                                                        fontFamily: '\'Oswald\', sans-serif',
                                                        fontSize: 16,
                                                        fontWeight:  600,
                                                        textTransform: 'uppercase',
                                                        whiteSpace: 'pre'
                                                    }}>
                                                        {'Email:  '}
                                                    </div>
                                                    <div style={{
                                                        fontFamily: '\'Oswald\', sans-serif',
                                                        fontSize:  16,
                                                        fontWeight:  400,
                                                        textTransform: 'uppercase'
                                                    }}>
                                                        <a href={"mailto:" + worker.email}>{worker.email}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {mobile ? (
                        <div style={{
                            zIndex: 2,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: '100%',
                            marginTop: 20}}
                             ref={this.aboutContainer}
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                maxWidth: 1200,
                                height: this.aboutContainer.current ? this.aboutContainer.current.clientHeight * 0.8 : '100%',
                                width: '100%'
                            }}>
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>

                                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                            <div style={{
                                                width: '100%',
                                                height: 300,
                                                overflow: 'hidden',
                                                marginBottom: 20
                                            }}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key: 'AIzaSyBY9T5AKBvGlIRPetSw5lC4uIOI6iMbKQ4' }}
                                                    defaultCenter={googleProps.center}
                                                    defaultZoom={googleProps.zoom}
                                                >
                                                    <AnyReactComponent
                                                        lat={57.78228548132105}
                                                        lng={14.25548755470512}
                                                        text="Nixco"
                                                    />
                                                </GoogleMapReact>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>):null}
                </div>
                {!mobile ? (
                    <div style={{width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', whiteSpace:'nowrap'}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            alignItems: 'flex-start',
                        }}>
                            <div style={{
                                fontFamily: '\'Ultra\', serif',
                                fontSize: 42}}
                            >
                                Adress
                            </div>
                            <div style={{
                                marginTop: 10,
                                fontFamily: '\'Roboto Slab\', serif',
                                fontSize: 24,
                                fontWeight: 200,
                                lineHeight: 1.5
                            }}>
                                Klövergatan 4<br/> 561 51 Huskvarna
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            alignItems: 'flex-start',
                        }}>
                            <div style={{
                                fontFamily: '\'Ultra\', serif',
                                fontSize: 42
                            }}>
                                Telefon
                            </div>
                            <div style={{
                                marginTop: 10,
                                fontFamily: '\'Roboto Slab\', serif',
                                fontSize: 24,
                                fontWeight: 200
                            }}>
                                <a href="tel:0731806087">0731 – 80 60 87</a>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            alignItems: 'flex-start',
                        }}>
                            <div style={{
                                fontFamily: '\'Ultra\', serif',
                                fontSize: 42
                            }}>
                                Mail
                            </div>
                            <div style={{
                                marginTop: 10,
                                marginBottom: 70,
                                fontFamily: '\'Roboto Slab\', serif',
                                fontSize: 24,
                                fontWeight: 200
                            }}>
                                <a href="mailto:info@nixco.se">info@nixco.se</a>
                            </div>
                        </div>
                    </div>):null}
            </div>
        );
    }
}

export default Contact;
//rgba(235,28,35,1)
