import * as React from "react";
import { motion } from "framer-motion";
import {Component} from "react";

const variants = {
    open: {
        y: 0,
        opacity: 1,

        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {

        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];
let submenuOpen = false;


class MenuItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submenuOpen: []
        }
    }

    componentDidMount() {
        let array = [];
        for(let i = 0; i < this.props.item; i++){
            array.push(false);
        }
    }

    render(){
        let {i, item, props, desktop, about} = this.props;
        let {submenuOpen} = this.state;
        //console.log(this.props);
        const style = {
            //border: `2px solid ${colors[i]}`,
            display: 'flex',
            //justifyContent: 'center',
            alignItems: 'center'
        };
        return (
            <motion.li style={{WebkitTapHighlightColor: 'transparent', display: desktop ? 'inline-block' : null, marginBottom: desktop ? 0 : 20}} key={i} variants={variants}>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: desktop ? null : 'flex-end', userSelect: 'none'}}>
                    <motion.div style={{marginRight: desktop ? 40: 0,marginTop: desktop ? 10 : 0, display: 'flex', flexDirection: desktop ? 'column':'row', width: '100%', alignItems: 'center', fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 400, textTransform: 'uppercase' }}
                                variants={variants}
                                whileHover={{scale: 1.1}}
                                whileTap={{scale: 0.95}}
                                onClick={() => {
                                    //console.log('klick')
                                    if (item.submenu) {
                                        let temp = this.state.submenuOpen;
                                        temp[i] = !temp[i]
                                        this.setState({
                                            submenuOpen: temp
                                        })
                                    }else if(item.link){
                                        /*if(desktop && about !== true){
                                         props.history.push('/services'+item.link)
                                        }else if(about){
                                            props.history.push('/about'+item.link)
                                        }else {*/
                                            props.history.push(item.link);
                                        //}
                                    }

                                }}
                    >
                        <div className="icon-placeholder" style={desktop ? {marginRight: 0, alignItems: 'center', display: 'flex'} :style}>
                            <img src={item.icon} style={{width: desktop ? '90%': '100%', filter: desktop ? 'invert(98%) sepia(27%) saturate(7489%) hue-rotate(336deg) brightness(112%) contrast(104%)' : null}}/>
                        </div>

                        <div className="text-placeholder" style={desktop ? {width: 'auto'}:style}>
                            {item.name}
                        </div>
                    </motion.div>
                    {submenuOpen[i] ? (
                        <div style={{width: '90%', marginTop: 15}}>
                            {item.submenu.map((item, i)=>{
                                return (
                                    <motion.div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 15}}
                                        //variants={variants}
                                        //animate={this.state.submenuOpen ? 'open' : 'closed'}
                                        key={i}
                                                whileHover={{scale: 1.1}}
                                                whileTap={{scale: 0.95}}
                                                onClick={() => {
                                                   // console.log('klick')
                                                    if (item.link) {
                                                        props.history.push( item.link)
                                                    }

                                                }}
                                    >
                                        <img src={item.icon} style={{width: 30}}/>
                                        <div style={{marginLeft: 20, fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 300, textTransform: 'uppercase'}}>
                                            {item.name}
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </motion.li>
        );
    };
}

export default MenuItem;
/*


 */
