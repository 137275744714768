import * as React from "react";
import { motion } from "framer-motion";
import  MenuItem  from "./MenuItem";
import home from '../../../assets/menu/home.png';
import services from '../../../assets/menu/img_535590.png';
import work from '../../../assets/menu/work.png';
import contact from '../../../assets/menu/phone.png';
import info from '../../../assets/menu/info.png';
import concrete from '../../../assets/triangular-shovel-with-liquid-concrete.png';
import water from '../../../assets/126363-200.png';
import spray from '../../../assets/8-512.png';
import wash from '../../../assets/pressure-washer.png';
import rust from '../../../assets/waterproof_water-repellent_resistant_water-resistant_weather_corrosion_protection_accessory-512.png';
import balcony from '../../../assets/balcony.png';
import floor from '../../../assets/house-renovation-worker-008-512.png';
import about from '../../../assets/menu/about-us.png';
import improvement from '../../../assets/menu/improve.png';
import enviroment from '../../../assets/menu/ecology.png';
import quality from '../../../assets/menu/winner.png';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};
const menu = [
    {
        name: 'Hem',
        icon: home,
        link: '/',
    },
    {
        name: 'Tjänster',
        icon: services,
        submenu: [
            {
                name:'Gjutning och Betongreprationer',
                icon: concrete,
                link: '/services/concrete'
            },
            {
                name: 'Vattenbilning',
                icon: water,
                link: '/services/watercrush'
            },
            {
                name: 'Sprutmålning',
                icon: spray,
                link: '/services/spraypaint'
            },
            {
                name: 'Fasadtvätt',
                icon: wash,
                link: '/services/wash',
            },
            {
                name: 'Korrosionsbehandling',
                icon: rust,
                link: '/services/corrosion',
            },
            {
                name: 'Balkongrenovering',
                icon: balcony,
                link: '/services/balcony',
            },
            {
                name: 'Industrigolv',
                icon: floor,
                link: '/services/floor'
            }
        ]

    },
    {
        name: 'Partners',
        icon: work,
        link: '/partners'
    },
    {
        name: 'Kontakt',
        icon: contact,
        link: '/contact'
    },
    {
        name: 'Information',
        icon: info,
        submenu:[
            {
                name:'Miljöpolicy',
                icon: enviroment,
                link:'/info/environment'
            },
            {
                name:'Kvalitetspolicy',
                icon: quality,
                link:'/info/quality'
            },
            {
                name:'Förbättringsproggram',
                icon: improvement,
                link:'/info/improvement'
            },
            {
                name:'Om Nixco',
                icon: about,
                link:'/info/about'
            },
        ]
    },
    /*{
        name: 'Login',
        icon: login,
        link: '/login'
    }*/
];

export const Navigation = ({props, desktop, about}) => (
  <motion.ul variants={variants} style={desktop ? {top: 5, width: 300, display: 'contents'}:{position: 'relative', marginBottom: 50, top: 0}}>
    {desktop && !about ? (menu[1].submenu.map((item, i) => {
        return (
            <MenuItem i={i} item={item} key={i} props={props} desktop={true} about={false}/>)
    })):(about ? (menu[4].submenu.map((item, i) => {
        return (
            <MenuItem i={i} item={item} key={i} props={props} desktop={true} about={true}/>)
    })):(menu.map((item, i) => {
        return (
            <MenuItem i={i} item={item} key={i} props={props}/>)
    })))}
  </motion.ul>
);

const itemIds = [0, 1, 2, 3, 4];

