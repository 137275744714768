import React, {Component} from 'react';

class Login extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '50vh', marginTop: 50}}>
                Login
            </div>
        );
    }
}

export default Login;
//rgba(235,28,35,1)
