import React, {Component} from 'react';
import tikkurila from '../assets/partners/tikkurila-200x100.png';
import huskvarna from '../assets/partners/huskvarna-200x100.png';
import hammelmann from '../assets/partners/hammelmann-2-200x100.png';
import induf from '../assets/partners/induf-200x100.png';
import noxyde from '../assets/partners/noxyde.png';
import htc from '../assets/partners/htc-200x100.jpg';

const partners =[
    {
        name:'Tikkurila',
        link:'https://tikkurila.se/',
        text:'Tikkurila är ett nordiskt färgföretag vars produkter tillverkas av noggrant utvalda råvaror som uppfyller högsta kvalitetsstandard. Vår vision är att skapa ytor som gör skillnad.',
        title:'Tikkurila',
        image: tikkurila
    },
    {
        name:'Noxyde',
        link:'https://www.rust-oleum.eu/product/noxyde',
        text:'Ultimate corrosion protection, even in severe conditions\n' +
            '200% elastic coat does not crack or peel\n' +
            'Excellent coverage of nuts, bolts and welds\n' +
            'Dry fall: saves preparation & clean up time\n' +
            'Primer and topcoat in one\n' +
            'UV- and impact-resistant\n' +
            'Single pack water-based elastomeric coating based on a modified acrylic binder with unequaled corrosion protection, >15 years in the highest corrosion class C5-M, according to ISO 12944.',
        title:'Noxyde',
        image: noxyde
    },
    {
        name:'Hammelmann',
        link:'https://www.hammelmann.com/',
        text:' Företaget grundades 1949 av Paul Hammelmann i Oelde, Westfalen, och beslutade att börja tillverka högtryckspumpar och rengöringssystem 1955 och därmed lägga grunden för den nuvarande verksamheten. Hammelmann blev en pionjär inom hydrodynamik och var ansvarig för många banbrytande innovationer.',
        title:'Hammelmann',
        image: hammelmann
    },
    {
        name:'HTC',
        link:'https://www.htc-floorsystems.com/',
        text:'HTC grundades 1987 och är ett snabbt växande teknikföretag som tillverkar och utvecklar ytbehandlingsprodukter. Ett patenterat slipningssystem för golv lanserades 1992. Idag är HTC ledande på den internationella marknaden för golvsystem baserat på diamantteknologi, med flera patentskyddade tekniska lösningar ute på marknaden. HTC har också utvecklat unika golvlösningar, inklusive en lösning för polerad betong under varumärket HTC Superfloor ™. HTC blev en del av Husqvarna Construction 2017. ',
        title:'HTC',
        image: htc
    },
    {
        name:'Induf',
        link:'http://www.induf.se/',
        text:'Induf AB är sedan 1987 svensk generalagent för Rust-Oleum® Industrial - en av Europas främsta producenter av problemlösande och skyddande färgprodukter för industriellt och yrkesmässigt underhåll.',
        title:'Induf',
        image: induf
    },
    {
        name:'Husqvarna',
        link:'https://www.husqvarna.com/se/',
        text:'Vi har gjort affärer sedan 1689 och använder vår expertis för att skapa symaskiner, cyklar, motorcyklar och köksutrustning. Nu hittar vi innovativa sätt att forma vår framtid.\n' +
            '\n' +
            'Idag är vi världens ledande producent av utomhuskraftprodukter för skogs-, park- och trädgårdsvård, vattningsprodukter och elverktyg för konstruktion.',
        title:'Husqvarna',
        image: huskvarna
    },
];

class Partners extends Component {

    constructor(props) {
        super(props);
        this.state = {
            link: ''
        };
        this.aRef = React.createRef();
    }

    render(){
        const {mobile} = this.props;
        return (
            <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(100vh - 400px - 50px)',
                    marginTop: 100,
                    alignItems: 'center'
                }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: 40,
                    fontFamily: '\'Oswald\', sans-serif',
                    textTransform: 'uppercase',
                    fontSize: 16,
                    fontWeight: 300,
                    letterSpacing: 4,
                    position: 'relative',
                    zIndex: 5,
                    whiteSpace: 'nowrap',
                    marginBottom: 50
                }}>
                    <div style={{
                        width: '100%',
                        backgroundColor: 'rgb(255,225,27)',
                        marginTop: 3,
                        marginLeft: '10%',
                        marginRight: 10,
                        height: 12,

                    }}/>
                    Partners
                    <div style={{
                        width: '100%',
                        backgroundColor: 'rgb(255,225,27)',
                        marginTop: 3,
                        marginLeft: 10,
                        marginRight: '10%',
                        height: 12
                    }}/>
                </div>

                    <div style={{
                        width: '80%',
                        maxWidth: 1500,
                        minHeight: '50vh',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, alignItems: 'center'}}>
                        {partners.map((obj, index)=>{
                            return(
                                <div key={index} style={{display: 'flex', width: mobile ? '90%' : '70%', flexDirection: mobile ? 'column':'row', marginBottom: 40, alignItems: 'center'}}

                                >
                                    <div style={{marginRight: 20, cursor: 'pointer',}}
                                        onClick={()=>{
                                        this.aRef.href = obj.link;
                                        this.aRef.click();
                                    }}
                                    >
                                        <img src={obj.image} style={index === 1 ?{maxWidth: mobile ? 180 : 180,backgroundColor: 'grey', padding:5,}:{maxWidth: mobile ? 200 : 200}}/>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div>
                                            {obj.name}
                                        </div>
                                        <div style={{fontFamily: '\'Roboto Slab\', serif', color: 'rgb(35,35,35)', fontSize: 16, fontWeight: 200}}>
                                            {obj.text}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>

                    </div>

                <a href={'#'} ref={ref=> this.aRef = ref}/>
            </div>
        );
    }
}

export default Partners;
