import React, {Component} from 'react';
import nixco from '../assets/Nixco.png';
import {withRouter} from "react-router";
import GoogleMapReact from 'google-map-react';
import aaa from '../assets/aaanixco.png';
import cancer from '../assets/vi-stodjer.png';

const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
        <Wrapped ref={forwardRef} {...otherProps} />
    ));
    const WithRouterAndRef = React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ));
    const name = Wrapped.displayName || Wrapped.name
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`
    return WithRouterAndRef
};

const googleProps = {
    center: {
        lat: 57.78238272031859,
        lng: 14.255498283541135
    },
    zoom: 13
};

const AnyReactComponent = ({ text }) => (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 500}}>
        <div style={{width: 15, height: 15, borderRadius: '50%', backgroundColor: 'red', marginBottom: 10}}/>
        {text}
    </div>);

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMap: false
        };
        this.aboutContainer = React.createRef();
    }

    componentDidMount() {
        //console.log(this.props.history.location)
    }

    render(){
        let {mobile} = this.props;
        return (
            <div style={{display: 'flex', flexDirection: 'column', marginTop: mobile ? 0:50}}>
                {this.props.history.location.pathname === '/' ? (mobile ? (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', textAlign: 'center'}}>

                    </div>) : (
                    <div style={{zIndex: 2, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', alignSelf: 'center', marginBottom: 50}}
                         ref={this.aboutContainer}
                    >
                        <div style={{display: 'flex', flexDirection: 'row', maxWidth: 1200, width: '100%'}}>
                            <div style={{width: '70%', height: 600, overflow: 'hidden'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyBY9T5AKBvGlIRPetSw5lC4uIOI6iMbKQ4' }}
                                    defaultCenter={googleProps.center}
                                    defaultZoom={googleProps.zoom}
                                >
                                    <AnyReactComponent
                                        lat={57.78228548132105}
                                        lng={14.25548755470512}
                                        text="Nixco"
                                    />
                                </GoogleMapReact>
                            </div>
                            <div style={{width: '50%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{display: 'flex', flexDirection: 'row',alignItems: 'center', justifyContent: 'flex-end',  width: '100%',marginLeft: '20%', marginTop: 40, fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 16, fontWeight: 300, letterSpacing: 4, position: 'relative', zIndex: 5, whiteSpace: 'nowrap'}}>
                                    Kontakt
                                    <div style={{width: '100%', backgroundColor: 'rgb(255,225,27)', marginTop: 3, marginLeft: 10, height: 12}}/>
                                </div>
                                <div style={{width: '80%', display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', marginTop: 30}}>
                                    <div style={{marginTop: 15, fontFamily: '\'Ultra\', serif', fontSize: 42}}>
                                        Adress
                                    </div>
                                    <div style={{marginTop: 10, fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200, lineHeight: 1.5}}>
                                        Klövergatan 4<br/> 561 51 Huskvarna
                                    </div>
                                </div>
                                <div style={{width: '80%', display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', marginTop: 30}}>
                                    <div style={{marginTop: 15, fontFamily: '\'Ultra\', serif', fontSize: 42}}>
                                        Telefon
                                    </div>
                                    <div style={{marginTop: 10, fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200}}>
                                        <a href="tel:036140010">036 - 14 00 10</a>
                                    </div>
                                </div>
                                <div style={{width: '80%', display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start', marginTop: 30}}>
                                    <div style={{marginTop: 15, fontFamily: '\'Ultra\', serif', fontSize: 42}}>
                                        Mail
                                    </div>
                                    <div style={{marginTop: 10, marginBottom: 70, fontFamily: '\'Roboto Slab\', serif', fontSize: 24, fontWeight: 200}}>
                                        <a href="mailto:info@nixco.se">info@nixco.se</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) :null}
                <div style={{display: 'flex', justifyContent: 'center', backgroundColor: 'rgba(26,25,24,1)', color: 'rgb(255,225,27)', textAlign: 'center', }}>
                    <div style={{justifyContent: 'space-between',display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 500}}>
                        <div style={{height: 30}}>
                            <div style={{height: '120%',
                                width: '100%',
                                background: 'linear-gradient(-135deg, yellow 5px, transparent 0) 0 5px, linear-gradient(135deg, yellow 5px, rgba(26,25,24,1) 0) 0 5px',
                                backgroundColor: 'yellow',
                                backgroundPosition: 'left bottom',
                                backgroundRepeat: 'repeat-x',
                                backgroundSize: '10px 10px'}}
                            >
                                <img src={nixco} style={{height: '80%', filter: 'invert(0%)', marginRight: 3}}/>
                            </div>

                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{fontFamily: '\'Ultra\', serif', fontSize: mobile ? 24 : 26,marginTop: 30}}>
                                Din
                            </div>
                            <div style={{fontFamily: '\'Ultra\', serif', fontSize: mobile ? 24 : 26,}}>
                                korresionsspecialist
                            </div>
                        </div>
                        <div style={{fontFamily: '\'Roboto Slab\', serif', color: 'rgb(220,220,220)', fontSize: mobile ? 14 : 16, fontWeight: 200, width: '90%',marginTop: 20}}>
                            NIXCO AB grundades 2009 av Jonas Cohen. Idag drivs företaget av två generationer företagare och har sex anställda.
                            Du hittar vårt kontor, lager och verkstadslokaler på Klövergatan 4 i Huskvarna.
                        </div>
                        {mobile ? (
                            <div style={{display: 'flex', flexDirection: 'column', width: '90%', justifyContent: 'space-around', marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent:'center', alignItems: 'center'}}>
                                    <div style={{backgroundColor: 'rgb(255,225,27)', width: '40%', height: 2, marginRight: 10}}/>
                                <div style={{fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 14, fontWeight: 300, letterSpacing: 4}}>
                                    Kontakt
                                </div>
                                    <div style={{backgroundColor: 'rgb(255,225,27)', width: '40%', height: 2, marginLeft: 10}}/>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%',color: 'rgb(220,220,220)'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{marginTop: 15, fontFamily: '\'Oswald\', serif', fontSize: 20,}}>
                                            Telefon
                                        </div>
                                        <div style={{marginTop: 10, fontFamily: '\'Roboto Slab\', serif', fontSize: 16, fontWeight: 100, whiteSpace: 'nowrap'}}>
                                            <a href="tel:0731806087">0731 – 80 60 87</a>
                                        </div>
                                        <div style={{marginTop: 15, fontFamily: '\'Oswald\', serif', fontSize: 20}}>
                                            Mail
                                        </div>
                                        <div style={{marginTop: 10, fontFamily: '\'Roboto Slab\', serif', fontSize: 16, fontWeight: 100}}>
                                            <a href="mailto:info@nixco.se">info@nixco.se</a>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column'}}
                                        onClick={()=>{
                                            this.setState({
                                                showMap: true
                                            })
                                        }}
                                    >

                                        <div style={{marginTop: 15, fontFamily: '\'Oswald\', serif', fontSize: 20}}>
                                            Adress
                                        </div>
                                        <div style={{marginTop: 10, fontFamily: '\'Roboto Slab\', serif', fontSize: 16, fontWeight: 100}}>
                                            Klövergatan 4<br/> 561 51 Huskvarna
                                        </div>
                                    </div>


                                </div>
                                {this.state.showMap ? (
                                    <div style={{position: 'fixed', zIndex:999999, top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                                        onClick={()=>{
                                            this.setState({
                                                showMap: false
                                            })
                                        }}
                                    >
                                        <div style={{ fontSize: 20, color: 'white', alignSelf: 'flex-end', margin: '5%'}}
                                            onClick={()=>{
                                                this.setState({
                                                    showMap: false
                                                })
                                            }}
                                        >
                                            Stäng
                                        </div>
                                        <div style={{width: '90%', height: '50vh', overflow: 'hidden'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyBY9T5AKBvGlIRPetSw5lC4uIOI6iMbKQ4' }}
                                    defaultCenter={googleProps.center}
                                    defaultZoom={googleProps.zoom}
                                >
                                    <AnyReactComponent
                                        lat={57.78228548132105}
                                        lng={14.25548755470512}
                                        text="Nixco"
                                    />
                                </GoogleMapReact>
                            </div>
                                    </div>
                                ):null}
                            </div>
                        ):null}
                        <div style={{height: mobile ? 70:100, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly',marginTop: 5, marginBottom: mobile ? 30 : 40}}>

                            <div style={{height:'100%', marginTop: 20, width: 115}}>
                                <img src={aaa} style={{height: '80%'}}/>
                            </div>
                            <div style={{height: '100%', marginTop: 5, width: 115}}>
                                <img src={cancer} style={{height: '100%'}}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouterAndRef(Footer);
//rgba(235,28,35,1)

/*
<div style={{display: 'flex', flexDirection: 'rox', width: '90%', justifyContent: 'space-around', fontFamily: '\'Oswald\', sans-serif', fontSize: mobile ? 16 : 18, fontWeight: 350, textTransform: 'uppercase' ,marginTop: mobile ? 40 : 60}}>
                            <div onClick={()=>{
                                this.props.history.push('/about');
                            }}>
                                Om Oss
                            </div>
                            <div onClick={()=>{
                                console.log('fdsfsdfsdfsfs', this.props)
                                this.props.scrollToFunc('services');
                            }}>
                                Tjänster
                            </div>
                            <div onClick={()=>{
                                this.props.scrollToFunc('workers')
                            }}>
                                Medarbetare
                            </div>
                            <div onClick={()=>{
                                this.props.history.push('/contact')
                            }}>
                                Kontakt
                            </div>

                        </div>
 */
