import './App.css';
import React, {Component} from 'react';
import { Switch, Route } from 'react-router-dom';
import FirstPage from './components/FirstPage';
import Footer from './components/Footer';
import Menu from './components/Menu';
import NotFound from './components/NotFound';
import Contact from "./components/Contact";
import Services from "./components/Services";
import About from "./components/About";
import Login from './components/Login';
import { withRouter } from 'react-router-dom';
import Home from "./components/desktop/Home";
import Partners from "./components/Partners";

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            scrollTo: '',
            scrollToFunc: this.scrollTo,
            landscape: false,
            mobile: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        window.addEventListener('load', this.handleWindowSizeChange);
        window.addEventListener("beforeunload", this.handleWindowSizeChange);
        //document.addEventListener('resize', this.handleWindowSizeChange);
        this.handleWindowSizeChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.handleWindowSizeChange();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        window.removeEventListener('load', this.handleWindowSizeChange);
        window.removeEventListener("beforeunload", this.handleWindowSizeChange);
        //document.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () =>{
        const width = window.innerWidth;
        const height = window.innerHeight;
        let landscape = false;
        let mobile = false;
        if(width < 768){
            mobile = true;
            if(width > height){
                landscape = true
            }
        }
        if(height < 768 && width > height){
            mobile = true;
            landscape = true;
        }
        //if(this.state.mobile !== mobile || this.state.landscape !== landscape){
            this.setState({
                mobile: mobile,
                landscape: landscape
            })
        //}
        //console.log(mobile, landscape, width, height);
    };

    scrollTo = (name)=>{
       // console.log(this.props);
        if(this.props.location.pathname !== '/'){
            this.props.history.push('/');
        }
        this.setState({
            scrollTo: name,
        })
    };

    render(){
        const {mobile, landscape} = this.state;

        return (
            <React.Fragment>
                <div>
                        <div className='Main'>
                            <Menu {...this.state} {...this.props}/>
                            <Switch>
                                <Route path="/contact" exact render={(props)=>{
                                    return <Contact {...props} {...this.state}/>}}
                                />
                                <Route path="/info/:id" exact render={(props)=>{
                                    return <About {...props} {...this.state}/>}}
                                />
                                <Route path="/partners" exact render={(props)=>{
                                    return <Partners {...props} {...this.state}/>}}
                                />
                                <Route path="/services/:id" exact render={(props)=>{
                                    return <Services {...props} {...this.state}/>}}
                                />
                                <Route path="/login" exact render={(props)=>{
                                    return <Login {...props} {...this.state}/>}}
                                />
                                <Route path="/" render={(props)=>{
                                    return mobile ? <FirstPage {...props} {...this.state}/> : <Home {...props}{...this.state}/>
                                }}
                                />
                                <Route component={NotFound}/>
                            </Switch>
                            <Footer {...this.props} {...this.state}/>
                        </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(App);
