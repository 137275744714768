import React, {Component} from 'react';
import tall from '../assets/125140427_3332318966895608_2045044488942562908_n.jpg';
import tal2 from '../assets/DSC_00051.jpg';
import concrete from '../assets/triangular-shovel-with-liquid-concrete.png';
import water from '../assets/126363-200.png';
import spray from '../assets/8-512.png';
import wash from '../assets/pressure-washer.png';
import rust from '../assets/waterproof_water-repellent_resistant_water-resistant_weather_corrosion_protection_accessory-512.png';
import balcony from '../assets/balcony.png';
import floor from '../assets/house-renovation-worker-008-512.png';
import man from '../assets/noimage.jpg';
import enginer from '../assets/engineer.png';
import {motion} from 'framer-motion';

const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

const story = {
    intro:{
        smallTitle: 'Specialister',
        title: 'Korrisions säkra',
        text: 'Etablera era fastigheter med ett fräscht utseende och funktion med Nixco\'s tjänster. Från gjutningar och betongreprationer till tak behandlingar, ni kan förvänta er en hög standard på era industri eller fastighets renoveringar.',
    },
    about: {
        smallTitle: 'Om Nixco',
        title: 'Vi höjer era fastighet\'s funktion',
        text: 'Hos oss får du alltid dina krav på kvalitet uppfyllda. Vår kvalitetsgaranti och vårt förbättringsprogram är en viktig del i arbetet att ständigt utveckla och förbättra vårt löfte till dig som kund.',
    },
    services: {
        smallTitle: 'Tjänster',
        title: 'Våra Tjänster',
        text: 'Nixco erbjuder olika tjänster inom fastigheter och industrilokaler, här är några av våra tjänster vi erbjuder',
    },
    workers: {
        smallTitle: 'Arbetare',
        title: 'Våra Specialister',
        text: 'Vi anställer bara personal med hög erfarenhet som har hög passion för våra tjänster',
    },
};

const services = [
    {
        img: concrete,
        title: 'Gjutningar och betongreprasioner',
        text: 'Allt inom betong gjutningar och reprationer, små och stora project',
        link:'/services/concrete'
    },
    {
        img: water,
        title: 'Vattenbilning',
        text: 'Vi erbjuder vattenbilning och vatten fräsning av betong med modern och säker utrustning',
        link:'/services/watercrush'
    },
    {
        img: spray,
        title: 'Sprutmålning',
        text: 'Högtrycks sprutmålning av fasader, tak och stora lokaler',
        link:'/services/spraypaint'
    },
    {
        img: wash,
        title: 'Fasadtvätt',
        text: 'Våra högtrycks sprutor garanterar ett bra jobb utan kemikalie inblandning',
        link:'/services/wash'
    },
    {
        img: rust,
        title: 'Korrosionsbehandling',
        text: 'Vi är specialister på korrosionsbehandla stål konstruktioner',
        link:'/services/corrosion'
    },
    {
        img: balcony,
        title: 'Balkongrenovering',
        text: 'Vår balkong service ger fasaden ett lyft och säkerställer en god funktion',
        link:'/services/balcony'
    },
    {
        img: floor,
        title: 'Industrigolv',
        text: 'Vi har behandlingar av alla typer av industrigolv',
        link:'/services/floor'
    },
];

const workers = [
    {
        img: man,
        name: 'Jonas Cohen',
        description: 'Grundare och VD för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    {
        img: man,
        name: 'Marcus Cohen',
        description: 'Produktions Ansvarig',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    /*{
        img: wide,
        name: 'Keith Fast',
        description: 'Medarbetare för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },
    {
        img: wide,
        name: 'Emil Fransson',
        description: 'Medarbetare för Nixco',
        email: 'keith_fast@hotmail.com',
        tel: '073-3730215',
    },*/
];

class FirstPage extends Component {

    constructor(props) {
        super(props);
        this.state={
            yposition: 0,
            mounted: false,
            top: false,
            about: false,
            services: false,
            workers: false,
            containersPosition: [],
            ypositionBottom: 0
        };
        this.serviceContaier = React.createRef();
        this.topContainer = React.createRef();
        this.workerContainer = React.createRef();
        this.aboutContainer = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //console.log(this.props)
        document.addEventListener('scroll', this.handleScroll);
        let height = window.innerHeight;
        let containersPosition = [
            this.topContainer.current.offsetTop,// - this.topContainer.current.clientHeight,
            this.aboutContainer.current.offsetTop,// - this.aboutContainer.current.clientHeight,
            this.serviceContaier.current.offsetTop,// - this.serviceContaier.current.clientHeight,
            this.workerContainer.current.offsetTop,// - this.workerContainer.current.clientHeight
        ];
        this.setState({
            mounted: true,
            yposition: window.scrollY,
            containersPosition: containersPosition,
            ypositionBottom: window.scrollY + height
        });
        //console.log('hej',this.state.ypositionBottom, containersPosition);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.scrollTo !== ''){
            if(nextProps.scrollTo === 'workers'){
                window.scrollTo({
                    top:nextState.containersPosition[3],
                    left: 0,
                    behavior: 'smooth'
                });
            }else if(nextProps.scrollTo === 'services'){
                window.scrollTo({
                    top:nextState.containersPosition[2],
                    left: 0,
                    behavior: 'smooth'
                });
            }
            this.props.scrollToFunc('');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevState.about){
            if(prevState.containersPosition[1] < prevState.ypositionBottom-200){
                this.setState({
                    about: true
                })
            }
        }
        if(!prevState.top){
            if(prevState.containersPosition[0] < prevState.ypositionBottom-200){
                this.setState({
                    top: true
                })
            }
        }
        if(!prevState.services){
            if(prevState.containersPosition[2] < prevState.ypositionBottom-200){
                this.setState({
                    services: true
                })
            }
        }
        if(!prevState.workers){
            if(prevState.containersPosition[3] < prevState.ypositionBottom-200){
                this.setState({
                    workers: true
                })
            }
        }
    }

    handleScroll = (event) =>{
        //console.log(window.scrollY, this.state.ypositionBottom, this.state.containersPosition);
        // this.forceUpdate()
        //if(this.state.yposition < window.scrollY + 10 || this.state.yposition < window.scrollY - 10) {
            this.setState({
                yposition: window.scrollY,
                ypositionBottom: window.scrollY + window.innerHeight
            })
        //}
        //this.state.yposition = window.scrollY;
        //this.state.ypositionBottom= window.scrollY + window.innerHeight;
    };

    onPan = (event) =>{
        //console.log(event);
    };

    render(){
        let scrollY = this.state.yposition;
        let contPos = this.state.containersPosition;

        return (
            <div style={{display: 'flex', flexDirection: 'column', width:'100%', marginTop: 50}}>
                <div style={{width: '100%', height: '100vh', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', background: 'white',}}
                     ref={this.topContainer}
                >

                    {this.state.mounted ?(<div>
                            <motion.div initial={{scale: 1.5, opacity: 0}}
                                        animate={{scale: 1, height: '100%', opacity: 1}}
                                        transition={{
                                            duration: 1,
                                            layoutX: { duration: 0.3 },
                                            layoutY: { duration: 0.3 },
                                        }}
                                        variants={variants}

                                        style={{overflow: 'hidden', width: '100%', height: '100%',position: 'absolute', top: /*this.topContainer.current ? (this.topContainer.current.clientHeight > scrollY ? scrollY/2 : 0) : 0*/0, left:0, zIndex: 0}}>
                                <div style={{backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}/>
                                <img src={tall} style={{objectFit:'cover', height: '100%', maxHeight: '100%', width: '100%',filter: 'grayscale(100%)', position: 'absolute', top: 0, left: 0, zIndex: -2}} alt={'Bakgrundsbild tak'}/>

                            </motion.div>
                            <div style={{color: 'rgb(255,225,27)', textAlign: 'center', maxWidth: 350, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 2}}>

                                <motion.div style={{marginBottom: 10}}//scrollY/30}}
                                            initial={{filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                                            animate={{filter: 'blur(0px)', scale: 1, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    <img src={enginer} style={{width: '10%', filter: 'invert(98%) sepia(27%) saturate(7489%) hue-rotate(336deg) brightness(112%) contrast(104%)'}}/>
                                </motion.div>
                                <motion.div style={{marginBottom: /*scrollY/30*/10, fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 14, fontWeight: 400, letterSpacing: 8, marginTop: 15}}
                                            initial={{filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                                            animate={{filter: 'blur(0px)', scale: 1, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    {story.intro.smallTitle}

                                </motion.div>
                                <motion.div style={{color: 'white', marginBottom: /*scrollY/30*/10, fontFamily: '\'Ultra\', serif', fontSize: 30, marginTop: 15}}
                                            initial={{filter: 'blur(10px)', scale: 0.8, opacity: 0}}
                                            animate={{filter: 'blur(0px)', scale: 1, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    {story.intro.title}

                                </motion.div>
                                <motion.div style={{marginBottom: /*scrollY/30*/10, backgroundColor: 'rgb(255,225,27)', width: '40%', height: 5,marginTop: 20, zIndex: 3}}
                                            initial={{width: 0}}
                                            animate={{width: '40%'}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'anticipate',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                />
                                <motion.div style={{color: 'rgb(230,230,230)', fontFamily: '\'Roboto Slab\', serif', fontSize: 20, fontWeight: 200, marginTop: 20, marginBottom: '40%', zIndex: 3, width: '80%', alignSelf: 'center'}}
                                            initial={{y: 100, opacity: 0}}
                                            animate={{y: 0, opacity: 1}}
                                    //variants={variants}

                                            transition={{
                                                ease: 'easeOut',
                                                duration: 1,
                                                delay: 0.5
                                            }}
                                >
                                    {story.intro.text}
                                </motion.div>
                            </div>
                        </div>
                    ):null}
                </div>
                <div style={{zIndex: 2, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', alignSelf: 'center'}}
                     ref={this.aboutContainer}
                >
                    <div style={{marginTop: 40, fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 14, fontWeight: 300, letterSpacing: 4, position: 'relative', zIndex: 5, whiteSpace: 'nowrap'}}

                    >
                        {story.about.smallTitle}
                        <motion.div
                            //initial={contPos[1] < scrollY ? {width: '100%'} : false}
                            animate={this.state.about ? 'inView' : 'outView'}
                            variants={{
                                inView:{width: '0%'},
                                outView:{width: '100%'}
                            }}

                            transition={{
                                ease: 'linear',
                                duration: 1
                            }}
                            style={{backgroundColor: 'white', width: '100%', height: '100%', zIndex: 5, position: 'absolute', top: 0, right: 0}}
                        />


                    </div>
                    <motion.div style={{marginTop: 20, fontFamily: '\'Ultra\', serif', fontSize: 36}}
                        //initial={{x: 100, filter: 'blur(10px)', opacity: 0}}
                                animate={this.state.about ? 'inView' : 'outView'}
                                variants={{
                                    outView:{x: 0, filter: 'blur(10px)', opacity: 0},
                                    inView:{x: 0, filter: 'blur(0px)', opacity: 1}
                                }}
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1,
                                }}
                    >
                        {story.about.title}
                    </motion.div>
                    <motion.div style={{marginTop: 50, color: 'rgb(100,100,100)', fontFamily: '\'Roboto Slab\', serif', fontSize: 20, fontWeight: 200, marginBottom: 80, paddingLeft: 20, paddingRight: 20}}
                        //initial={{y: 100, opacity: 0}}
                                animate={this.state.about ? 'inView' : 'outView'}
                                variants={{
                                    inView: {y: 0, opacity: 1},
                                    outView: {y: 0, opacity: 0}
                                }}
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1,
                                }}
                    >
                        {story.about.text}
                    </motion.div>
                </div>
                <div style={{overflow: 'hidden',display: 'flex', flexDirection: 'column', position: 'relative', width: '100%'}}
                     ref={this.serviceContaier}
                >
                    <motion.div style={{ position: 'absolute', top: /*this.serviceContaier.current ? (this.serviceContaier.current.clientHeight + this.serviceContaier.current.offsetTop > scrollY ? ((scrollY-this.serviceContaier.current.offsetTop)/2):0)  : 0*/0, left: 0, width: '100%', zIndex: -2, height: '100%'}}
                        //initial={{scale: 1.5, filter: 'blur(10px)'}}
                                animate={this.state.services ? 'inView' : 'outView'}
                                variants={{
                                    inView:{scale: 1, filter: 'blur(2px)'},
                                    outView:{scale: 1.5, filter: 'blur(10px)'}
                                }}
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1
                                }}
                    >
                        <div style={{backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: this.serviceContaier.current ? this.serviceContaier.current.clientHeight: 0, position: 'absolute', top: 0, left: 0}}/>
                        <img src={tal2} style={{filter: 'grayscale(100%)', height: this.serviceContaier.current ? this.serviceContaier.current.clientHeight: 0, position: 'absolute', top: 0, left: 0, zIndex: -4}}/>
                    </motion.div>
                    <div style={{color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                            <motion.div style={{marginTop: 40,fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 14, fontWeight: 300, letterSpacing: 4, overflow: 'hidden'}}
                                //initial={{width: 0}}
                                        animate={this.state.services ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{width: '100%'},
                                            outView:{width: 0}
                                        }}
                                        transition={{
                                            duration: 2,
                                            ease: 'linear',
                                            //delay: 1
                                        }}
                            >

                                {story.services.smallTitle}

                            </motion.div>

                            <motion.div style={{marginTop: 20, fontFamily: '\'Ultra\', serif', fontSize: 36}}
                                //initial={{y: -40, opacity: 0, filter: 'blur(10px)'}}
                                        animate={this.state.services ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{y:0, opacity: 1, filter: 'blur(0px)'},
                                            outView:{y: -40, opacity: 0, filter: 'blur(10px)'}
                                        }}
                                        transition={{
                                            duration: 1,
                                            //delay: 1,
                                            ease: 'easeOut'
                                        }}
                            >
                                {story.services.title}
                            </motion.div>
                            <motion.div style={{color: 'rgb(230,230,230)', marginTop: 20, fontFamily: '\'Roboto Slab\', serif', fontSize: 20, fontWeight: 200, marginBottom: 30}}
                                //initial={{y: 40, opacity: 0}}
                                        animate={this.state.services ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{y:0, opacity: 1},
                                            outView:{y: 40, opacity: 0}
                                        }}
                                        transition={{
                                            duration: 1,
                                            //delay: 1,
                                            ease: 'anticipate'
                                        }}
                            >
                                {story.services.text}
                            </motion.div>
                        </div>
                        {services.map((service)=>{
                            return (
                                <div style={{transition: 'all .2s ease-in-out', cursor: 'pointer',display: 'flex', flexDirection: 'column', maxWidth: '50%', alignSelf: 'center', alignItems: 'center', userSelect:'none'}}
                                     onClick={()=>{
                                         //console.log('hrj')
                                         this.props.history.push(service.link);
                                     }}
                                     onMouseDown={(e)=>{
                                         e.currentTarget.style.transform = 'scale(0.9)';
                                     }}
                                     onTouchStart={(e)=>{
                                         e.currentTarget.style.transform = 'scale(0.9)';
                                     }}
                                     onTouchMove={(e)=>{
                                         e.currentTarget.style.transform = 'scale(0.9)';
                                     }}
                                     onMouseOver={(e)=>{
                                         e.currentTarget.style.transform = 'scale(1.1)';
                                         //e.currentTarget.style.width = '250px';
                                     }}
                                     onMouseLeave={(e)=>{
                                         e.currentTarget.style.transform = 'scale(1)';
                                     }}
                                     onTouchEnd={(e)=>{
                                         e.currentTarget.style.transform = 'scale(1)';
                                     }}

                                >
                                    <motion.div style={{width: '100%', height: '100%', overflow: 'hidden'}}
                                        //initial={{width: '30%', y: 50, opacity: 0}}
                                                animate={this.state.services ? 'inView' : 'outView'}
                                                variants={{
                                                    inView:{width: '100%', y:0, opacity: 1},
                                                    outView:{width: '30%', y: 50, opacity: 0}
                                                }}
                                                transition={{
                                                    duration: .8,
                                                    ease: 'linear'
                                                }}
                                    >
                                        <img src={service.img} style={{filter: 'invert(98%) sepia(27%) saturate(7489%) hue-rotate(336deg) brightness(112%) contrast(104%)', width: '30%', height: '100%'}}/>
                                    </motion.div>
                                    <motion.div style={{marginTop: 15, fontFamily: '\'Oswald\', sans-serif', fontSize: 20, fontWeight: 600, textTransform: 'uppercase'}}
                                        //initial={{y: 50, opacity: 0}}
                                                animate={this.state.services ? 'inView' : 'outView'}
                                                variants={{
                                                    inView:{y: 0, opacity: 1},
                                                    outView:{y: 50, opacity: 0}
                                                }}
                                                transition={{
                                                    duration: 1,
                                                    ease: 'anticipate',
                                                    delay: .5
                                                }}
                                    >
                                        {service.title}
                                    </motion.div>
                                    <motion.div style={{marginTop: 15, color: 'rgb(230,230,230)', fontFamily: '\'Roboto Slab\', serif', fontSize: 16, fontWeight: 100, marginBottom: 30}}
                                        //initial={{y: 50, opacity: 0}}
                                                animate={this.state.services ? 'inView' : 'outView'}
                                                variants={{
                                                    inView:{y: 0, opacity: 1},
                                                    outView:{y: 50, opacity: 0}
                                                }}
                                                transition={{
                                                    duration: 1,
                                                    ease: 'anticipate',
                                                    delay:.8
                                                }}
                                    >
                                        {service.text}
                                    </motion.div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div style={{backgroundColor: 'white',  width: '100%', color: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}
                     ref={this.workerContainer}
                >
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{marginTop: 40, fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 14, fontWeight: 300, letterSpacing: 4, position: 'relative'}}>
                            {story.workers.smallTitle}
                            <motion.div
                                //initial={{width: '100%'}}
                                animate={this.state.workers ? 'inView' : 'outView'}
                                variants={{
                                    inView:{width: '0%'},
                                    outView:{width: '100%'}
                                }}
                                transition={{
                                    ease: 'linear',
                                    duration: 1
                                }}
                                style={{backgroundColor: 'white', width: '100%', height: '100%', zIndex: 5, position: 'absolute', top: 0, right: 0}}
                            />
                        </div>
                        <motion.div style={{marginTop: 20, fontFamily: '\'Ultra\', serif', fontSize: 36}}
                            //initial={{x: -200, opacity: 0}}
                                    animate={this.state.workers ? 'inView' : 'outView'}
                                    variants={{
                                        inView:{x: 0, opacity: 1},
                                        outView:{x: 0, opacity: 0}
                                    }}
                                    transition={{
                                        ease: 'easeIn',
                                        duration: 0.8
                                    }}
                        >
                            {story.workers.title}
                        </motion.div>
                        <motion.div style={{marginTop: 20, color: 'rgb(100,100,100)', fontFamily: '\'Roboto Slab\', serif', fontSize: 20, fontWeight: 200, marginBottom: 40, paddingLeft: 20, paddingRight: 20}}
                            //initial={{x: 200, opacity: 0}}
                                    animate={this.state.workers ? 'inView' : 'outView'}
                                    variants={{
                                        inView:{x: 0, opacity: 1},
                                        outView:{x: 0, opacity: 0}
                                    }}
                                    transition={{
                                        ease: 'easeIn',
                                        duration: 0.8
                                    }}
                        >
                            {story.workers.text}
                        </motion.div>
                    </div>
                    {workers.map((worker)=>{
                        return (
                            <div style={{display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center', alignItems: 'center'}}>
                                <div style={{position: 'relative', width: '100%'}}
                                >
                                    <img src={worker.img} style={{filter: 'grayscale(100%)', width: '65%'}}/>
                                    <motion.div
                                        //initial={{width: '100%'}}
                                        animate={this.state.workers ? 'inView' : 'outView'}
                                        variants={{
                                            inView:{width: '0%'},
                                            outView:{width: '100%'}
                                        }}
                                        transition={{
                                            ease: 'easeIn',
                                            duration: 1
                                        }}
                                        style={{backgroundColor: 'white', width: '100%', height: '100%', zIndex: 5, position: 'absolute', top: 0, right: 0}}
                                    />
                                </div>
                                <motion.div style={{marginTop: 15, fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 600, textTransform: 'uppercase'}}
                                    //initial={{opacity: 0, x: -50}}
                                            animate={this.state.workers ? 'inView' : 'outView'}
                                            variants={{
                                                inView:{opacity: 1, x: 0},
                                                outView:{opacity: 0, x: 0}
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: 'easeOut',
                                                //delay: 1
                                            }}
                                >
                                    {worker.name}
                                </motion.div>
                                <motion.div style={{marginTop: 10, color: 'rgb(100,100,100)', fontFamily: '\'Roboto Slab\', serif', fontSize: 14, fontWeight: 100, marginBottom: 30, }}
                                    //initial={{opacity: 0, x: 50}}
                                            animate={this.state.workers ? 'inView' : 'outView'}
                                            variants={{
                                                inView:{opacity: 1, x: 0},
                                                outView:{opacity: 0, x: 0}
                                            }}
                                            transition={{
                                                duration: 1,
                                                ease: 'easeOut',
                                                //delay: 1
                                            }}
                                >
                                    {worker.description}
                                </motion.div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
// this.serviceContaier.current.clientHeight this.serviceContaier.current.offsetTop - (scrollY )
export default FirstPage;
