import React, {Component} from 'react';
import {withRouter} from "react-router";
import nixco from "../assets/Nixco.png";
import Menuu from "./common/menu/Menu";
import {Navigation} from "./common/menu/Navigation";

const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
        <Wrapped ref={forwardRef} {...otherProps} />
    ));
    const WithRouterAndRef = React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ));
    const name = Wrapped.displayName || Wrapped.name
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`
    return WithRouterAndRef
};

const show = {
    open: {
        display: ''
    },
    closed: {
        display: 'none'
    }
};

const show2 = {
    open: {
        width: '100%',
        height: '100%',
        minHeight: '100vh'
    },
    closed: {
        width: 50,
        height: 50,
        minHeight: '0%'
    }
};

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state={
            imgWidth: 0,
            expandTjanst: false,
            expandAbout: false,
        };
        this.img = React.createRef();
    }

    componentDidMount() {
        /*this.setState({
            imgWidth: this.img.current.clientWidth,
            innerWidth: window.innerWidth
        })*/
        //console.log(this.props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /*if(prevState.imgWidth !== this.img.current.clientWidth || window.innerWidth !== prevState.innerWidth) {
            this.setState({
                imgWidth: this.img.current.clientWidth,
                innerWidth: window.innerWidth
            })
        }*/
    }


    render(){
        let {mobile, location} = this.props;
        let path = location.pathname.split('/')[1].toLowerCase();
       // console.log(path);
        if(mobile) {
            return (
                <div key={Date.now()} style={{
                    backgroundColor: 'yellow',
                    height: 50,
                    width: '100%',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 100
                }}>
                    <div style={{
                        background: 'linear-gradient(-135deg, yellow 5px, transparent 0) 0 5px, linear-gradient(135deg, yellow 5px, transparent 0) 0 5px',
                        backgroundColor: 'transparent',
                        backgroundPosition: 'left bottom',
                        backgroundRepeat: 'repeat-x',
                        backgroundSize: '10px 10px',
                        position: 'absolute',
                        bottom: -10,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}>
                    </div>
                    <Menuu props={this.props}/>

                    <img src={nixco}
                         style={{
                             height: '100%',
                             zIndex: 200,
                             position: 'absolute',
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)'
                         }}
                         onClick={() => {
                             this.props.history.push('/')
                         }}
                    />


                </div>
            );
        }else {
            return (
                <div key={Date.now()} style={{
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    height: 50,
                    width: '100%',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    display: 'flex',
                    justifyContent: 'center',
                }}>

                    <div style={{cursor:'pointer',width: 66,position: 'relative', marginRight: 50, height: '100%', alignSelf: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 300, textTransform: 'uppercase', color: 'rgb(230,230,230)'}}
                         onClick={(e)=>{
                             this.setState({
                                 expandTjanst: false,
                                 expandAbout: !this.state.expandAbout
                             })
                         }}

                    >
                        <div style={{textDecoration: path === 'info' ? 'underline':'none',textUnderlinePosition: 'under',
                    transition: 'all .2s ease-in-out',position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap'}}
                             onMouseOver={(e)=>{
                                 e.target.style.fontSize = '20px';
                             }}
                             onMouseLeave={(e)=>{
                                 e.target.style.fontSize = '16px';
                             }}
                        > Information</div>
                        {this.state.expandAbout ? (
                            <div>

                                <div className={'expandTjanst'} style={{zIndex: -1, position: 'fixed', top: 50, left: 0, width: '100vw', height: 100, backgroundColor: 'rgba(0,0,0,.6)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Navigation desktop={true} about={true} props={this.props}/>

                                </div>

                            </div>
                        ):null}
                    </div>
                    <div style={{cursor:'pointer',width: 66,position: 'relative', marginRight: 50, height: '100%', alignSelf: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 300, textTransform: 'uppercase', color: 'rgb(230,230,230)'}}
                         onClick={(e)=>{
                             this.setState({
                                 expandTjanst: !this.state.expandTjanst,
                                 expandAbout: false
                             })
                         }}

                    >
                        <div style={{textDecoration: path === 'services' ? 'underline':'none',textUnderlinePosition: 'under',
                    transition: 'all .2s ease-in-out',position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                             onMouseOver={(e)=>{
                                 e.target.style.fontSize = '20px';
                             }}
                             onMouseLeave={(e)=>{
                                 e.target.style.fontSize = '16px';
                             }}
                        > Tjänster</div>
                        {this.state.expandTjanst ? (
                            <div>

                                <div className={'expandTjanst'} style={{zIndex: -1, position: 'fixed', top: 50, left: 0, width: '100vw', height: 100, backgroundColor: 'rgba(0,0,0,.6)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Navigation desktop={true} props={this.props}/>

                                </div>

                            </div>
                        ):null}
                    </div>

                    <div style={{cursor:'pointer',display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgb(255,225,27)', width: 210,height: '120%', paddingRight: 0, paddingLeft: 0}}
                         onClick={()=>{
                             this.setState({
                                 expandTjanst: false,
                                 expandAbout: false
                             });
                             this.props.history.push('/')
                         }}
                    >
                        <div style={{height: 30}}>

                        </div>
                        <div style={{height: '100%', width:'100%'}}>
                            <div style={{height: '120%',
                                width: '100%',
                                background: 'linear-gradient(-135deg, rgb(255,225,27) 5px, transparent 0) 0 5px, linear-gradient(135deg, rgb(255,225,27) 5px, transparent 0) 0 5px',
                                backgroundColor: 'transparent',
                                backgroundPosition: 'left bottom',
                                backgroundRepeat: 'repeat-x',
                                backgroundSize: '14px 12px',
                            }}
                            >
                                <img src={nixco} style={{width: '90%', filter: 'invert(0%)', backgroundColor: 'rgb(255,225,27)', paddingLeft: 9}}/>
                            </div>
                        </div>

                    </div>
                    <div style={{textDecoration: path === 'partners' ? 'underline':'none',textUnderlinePosition: 'under',
                    transition: 'all .2s ease-in-out',cursor:'pointer',marginLeft: 50, height: '100%', alignSelf: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 300, textTransform: 'uppercase', color: 'rgb(230,230,230)'}}
                         onClick={()=>{
                             this.setState({
                                 expandTjanst: false,
                                 expandAbout: false
                             });
                             this.props.history.push('/partners')
                         }}
                         onMouseOver={(e)=>{
                             e.target.style.transform = 'scale(1.25)';
                         }}
                         onMouseLeave={(e)=>{
                             e.target.style.transform = 'scale(1)';
                         }}
                    >
                        Partners
                    </div>
                    <div style={{textDecoration: path === 'contact' ? 'underline':'none',textUnderlinePosition: 'under',
                    transition: 'all .2s ease-in-out',cursor:'pointer',marginLeft: 50, height: '100%', alignSelf: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: '\'Oswald\', sans-serif', fontSize: 16, fontWeight: 300, textTransform: 'uppercase', color: 'rgb(230,230,230)'}}
                         onClick={()=>{
                             this.setState({
                                 expandTjanst: false,
                                 expandAbout: false
                             });
                             this.props.history.push('/contact')
                         }}
                         onMouseOver={(e)=>{
                             e.target.style.transform = 'scale(1.25)';
                         }}
                         onMouseLeave={(e)=>{
                             e.target.style.transform = 'scale(1)';
                         }}
                    >
                        Kontakt
                    </div>
                </div>
            )
        }
    }
}

export default withRouterAndRef(Menu);
/*
<div style={{zIndex: 999999, backgroundColor: 'rgba(255,255,27,1)', position:'absolute', top: 50, left: 0, width: 50, height: 50,}}>
                                    <div style={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden'}}>
                                        <div style={{position: 'absolute', top: -35, left: -24, borderRadius: '50%', width: '100%', height: '100%', backgroundColor: 'rgba(127,127,127,1)'}}/>
                                        <div style={{position: 'absolute', top: -35, left: 24, borderRadius: '50%', width: '100%', height: '100%', backgroundColor: 'rgba(127,127,127,1)'}}/>
                                    </div>
                                </div>
 */
