import React, {Component} from 'react';
import LoadImages from './common/LoadImages';
import "react-image-gallery/styles/css/image-gallery.css";
import arrow from "../assets/arrow-down-sign-to-navigate.png";
import {motion} from "framer-motion";
import {Redirect} from "react-router";

let images;

const tjanster = {
    concrete: {
        text: 'Vi formgjuter alla typer av konstruktioner, behörighet finns inom företaget. Mångårig erfarenhet finns avseende formgjutning och reparationer av betong vattenreservoarer, vattentorn, avloppsreningsverk, p-hus och broar.\n' +
            'All utförandekompetens, licenser och behörigheter finns inom företaget.',
        title: 'Gjutningar och betongreprationer'

    },
    watercrush:{
        text: 'Med modernaste teknik vattenbilar och blästrar vi i betong samt rengör stål och plåt från rost och tidigare färg. Vi arbetar med vattentryck upp till 2500 bar. D.v.s. 20 gånger högre än en vanlig högtryckstvätt.' +
            '\n' +
            'Detta är en miljövänlig och effektiv metod som inte förorenar omgivande markytor.',
        title: 'Vattenbilning och Vattenblästring'
    },
    spraypaint: {
        text: 'Med våra färgsprutor appliceras färgen snabbt och kostnadseffektivt.' +
            '\n' +
            'Färgen trycks fram till sprutmunstycket via slangar utan inblandning av luft. Detta skapar en tjock och jämn yta på målningsobjektet.' +
            '\n' +
            'Sprutmålning fungerar bra på alla typer av material så som stål, plåt, glasfiber, betong och trä.',
        title: 'Sprutmålning'
    },
    wash: {
        text: 'Med miljövänliga teknik rengörs fasaden från föroreningar. Oftast med tryck under 500 bar.' +
            '\n' +
            'För att minska miljöpåverkan så används varmvatten i stället för kemiska produkter',
        title: 'Fasadtvätt'
    },
    corrosion: {
        text: 'Utförs med pensel, rollers eller sprutmålning. Förbehandling är en viktig del som utgör skälva grunden för resultatet. Oftast avlägsnas den gamla färgen och gravrost medels vattenblästring. För att skapa högre rengöringsgrad kompletteras metoden med hjälp av mekanisk rengöring och sandblästring.' +
            '\n' +
            'Själva rostskyddet byggs upp med rostskyddandefärg med högsta skyddsklass. Toppskikt utförs enligt kundens önskemål på kulör och glans.',
        title: 'Korrosions - behandling'
    },
    balcony: {
        text: 'Efter besiktning lämnas offert utifrån de aktuella behoven. Bilning, gjutning och nya tätskikt\n' +
            'utförs samt byten av räcken och fronter genomförs enligt kundens behov och önskemål.\n' +
            'Inglasning av befintliga balkonger kan även offereras och beställas',
        title: 'Balkongrenovering'
    },
    floor: {
        text: 'Vi utför slipning, polering, impregnering av betong. Applicering av polyuretan, epoxi och akryl är en del av arbetet.',
        title: 'Industrigolv'
    }
};


class Services extends Component {

    constructor(props) {
        super(props);
        /*let name = history.location.pathname.split('/');
        console.log(name);
        name = name[name.length-1];
        images = this.importAll(require.context('../assets/services/' + name, false, /\.(png|jpe?g|svg)$/));*/
        this.state = {
            serviceName: '',
            selectedImage: 0,
            sliderWidth:0,
            bigImg: false,
            innerWidth: window.innerWidth
        };

        this.sliderRef = React.createRef();
    }

    componentDidMount() {
        let name = this.props.history.location.pathname.split('/');
        //console.log(name);
        name = name[name.length-1];
        if(!tjanster[name]){
            this.props.history.push('/');
            return;
        }
        images = LoadImages.importFiles(name);
        //console.log('lofdsfds', images)
        this.setState({
            serviceName: name,
            innerWidth: window.innerWidth
        });
        document.addEventListener("dragstart", this.dragstart, false);

        window.addEventListener('resize', this.resize);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let name = this.props.history.location.pathname.split('/');
        name = name[name.length-1];
        if(!tjanster[name]){
            this.props.history.push('/')
            return;
        }
        if(name !== this.state.serviceName){
            images = LoadImages.importFiles(name);
            this.setState({
                serviceName: name,
                selectedImage: 0
            })
        }
        if(this.sliderRef && this.sliderRef.current && this.state.sliderWidth === 0) {
            //console.log(this.sliderRef.current, 'hej');
            this.sliderRef.current.addEventListener('scroll', this.sliderScroll)
            this.setState({
                sliderWidth: this.sliderRef.current.clientWidth
            });
            //console.log('ffffff',this.sliderRef.current.clientWidth)
        }
        if(prevState.selectedImage !== this.state.selectedImage && this.sliderRef && this.sliderRef.current){
            this.sliderRef.current.scrollTo(((((this.state.selectedImage) % images.length) + images.length) % images.length)*200, 0 );
            this.setState({
                scrollPosition: this.sliderRef.current.scrollLeft
            })
        }
    }

    componentWillUnmount() {
        //this.sliderRef.current.removeEventListener('scroll', this.sliderScroll)
        window.removeEventListener('resize', this.resize);
        document.removeEventListener('dragstart',this.dragstart)
        if(this.sliderRef && this.sliderRef.current && this.state.sliderWidth === 0) {
            this.sliderRef.current.removeEventListener('scroll', this.sliderScroll)
        }
    }

    dragstart = (event) =>{
         var img = new Image();
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
            event.dataTransfer.setDragImage(img, 0, 0);
    };

    resize = () =>{
        let width = window.innerWidth;
        if(this.state.innerWidth - width < -100 || this.state.innerWidth - width > 100){
            this.setState({
                innerWidth: width
            })
        }
    };

    sliderScroll = (e) =>{
        //console.log('scroll', e.target.scrollLeft)
        this.setState({
            scrollPosition: e.target.scrollLeft
        })
    };

    calcImgArray = (imageIndex, numberOfImages) =>{
        let arr = []
        for(let i = (((imageIndex - (numberOfImages/2)) % images.length) + images.length) % images.length; arr.length < numberOfImages; i++) {
            arr.push(images[(((i) % images.length) + images.length) % images.length])
        }
        //console.log('arr', arr, numberOfImages, (((imageIndex - (numberOfImages/2)) % images.length) + images.length) % images.length, (((imageIndex + (numberOfImages/2)) % images.length) + images.length) % images.length)
        return arr
    };

    handleTouchStart = (event) =>{
        this.setState({
            touchStart: event.targetTouches[0].clientX,
            touching: true
        })
    };

    handleTouchMove = (event) =>{
         this.setState({
            touchEnd: event.targetTouches[0].clientX,

        })
    };

    handleTouchEnd = () =>{
        let {touchStart, touchEnd} = this.state;
        if (touchStart - touchEnd > 100) {
        // do your stuff here for left swipe
        this.moveSliderRight();
    }else if (touchStart - touchEnd < -100) {
        // do your stuff here for right swipe
        this.moveSliderLeft();
    }else{
            this.setState({
                touching: false
            })
        }
    };

    moveSliderLeft = () =>{
      this.setState({
          touching: false,
          selectedImage: (((this.state.selectedImage - 1) % images.length) + images.length) % images.length
      })
    };

    moveSliderRight = () =>{
        this.setState({
            touching: false,
          selectedImage: (((this.state.selectedImage + 1) % images.length) + images.length) % images.length
      })
    };

    render(){
        const {history} = this.props;
        const {scrollPosition, innerWidth, touching} = this.state;
        let name = history.location.pathname.split('/');
        //console.log(name);
        name = name[name.length-1];
        const tjanst = tjanster[name];
        //console.log(name, tjanst)
        if(!this.state.serviceName){
            return(
                <div>
                    loading
                </div>
            )
        }
        if(!tjanst){
            return (
                <Redirect to={'/'}/>
            )
        }
        let imageIndex = (((this.state.selectedImage) % images.length) + images.length) % images.length;
        let numberOfImages = Math.floor(this.state.sliderWidth / 200);
        let imageObj = this.calcImgArray(imageIndex, numberOfImages);
        return (
            <div style={{display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 400px - 50px)', marginTop: innerWidth > 1250 ? 85 : (innerWidth > 500 ? 50 : 35), width: '100%', alignItems: 'center'}}>
                <div style={{width: innerWidth > 1250 ? '80%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 1500}}>

                    <div style={{alignSelf: 'flex-start', width: '100%',  display: 'flex', alignItems: 'center', flexDirection: innerWidth > 1250 ? 'row': 'column', justifyContent: 'space-between'}}>
                        <div style={{position: 'relative', width: innerWidth > 1250 ? 600 : '100%', height: innerWidth > 1250 ? 600: 500, /*background: 'radial-gradient(circle, rgba(26,25,24,1) 0%, rgba(255,255,255,1) 85%)',*/ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img className={touching ? 'nonSelect fadeout':'nonSelect fadein'} key={this.state.selectedImage} src={images[this.state.selectedImage]} style={{cursor: 'pointer', backgroundColor: innerWidth > 1250 ? 'rgb(255, 225, 27)' : null, boxShadow: '0px 0px 11px 3px rgba(0,0,0,0.75)',objectFit: 'contain', width: 'inherit', height: 'inherit'}}
                                 onClick={()=>{
                                     if(innerWidth >1250) {
                                         this.setState({
                                             bigImg: true
                                         })
                                     }
                                 }}
                                 onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                                 onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                                 onTouchEnd={() => this.handleTouchEnd()}
                            />
                            <div style={{position: 'absolute', left: 0, top: 'calc(50% - 20)', width: 40, height: 40,opacity: .8}}
                                 onClick={(e)=>{
                                     e.preventDefault();
                                     e.stopPropagation();
                                     this.setState({
                                         selectedImage: (((this.state.selectedImage - 1) % images.length) + images.length) % images.length
                                     })
                                 }}
                                 onTouchEnd={(e)=>{
                                     e.preventDefault();
                                     e.stopPropagation();
                                     this.setState({
                                         selectedImage: (((this.state.selectedImage - 1) % images.length) + images.length) % images.length
                                     })
                                 }}
                            >
                                <motion.div className={'nonSelect'} style={{cursor: 'pointer'}}
                                            whileHover={{scale: 1.2}}
                                            whileTap={{scale: 0.7}}
                                >
                                    <img className={'nonSelect'} src={arrow} style={{width: 35,transform:'rotate(90deg)'}}/>
                                </motion.div>
                            </div>
                            <div style={{position: 'absolute', right: 0, top: 'calc(50% - 20)', width: 40, height: 40,opacity: .8, userSelect: 'none', pointerEvents: 'auto'}}
                                 onClick={(e)=>{
                                     e.preventDefault();
                                     e.stopPropagation();
                                     //console.log((((this.state.selectedImage + 1) % images.length) + images.length) % images.length )
                                     this.setState({
                                         selectedImage: (((this.state.selectedImage + 1) % images.length) + images.length) % images.length
                                     })
                                 }}
                                 onTouchEnd={(e)=>{
                                     e.preventDefault();
                                     e.stopPropagation();
                                     this.setState({
                                         selectedImage: (((this.state.selectedImage + 1) % images.length) + images.length) % images.length
                                     })
                                 }}
                            >
                                <motion.div className={'nonSelect'} style={{cursor: 'pointer', userSelect: 'none'}}
                                            whileHover={{scale: 1.2}}
                                            whileTap={{scale: 0.7}}
                                >
                                    <img className={'nonSelect'} src={arrow} style={{width: 35,transform:'rotate(270deg)', userSelect: 'none', pointerEvents: 'none'}} draggable={false}/>
                                </motion.div>
                            </div>
                            <div style={{position: 'absolute', bottom: innerWidth > 1250 ? 0 : 10, left: 0, height: 20, width:'100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                {images.map((img, index)=>{
                                    return(
                                        <div key={index}
                                             style={{cursor: 'pointer', borderRadius: '50%',margin: 3, marginBottom: 15, width: 10, height: 10, border: 'solid', borderWidth: 2, borderColor: 'rgba(0,0,0,1)', opacity: .8, backgroundColor: (((this.state.selectedImage) % images.length) + images.length) % images.length === index  ? 'rgba(0,0,0,1)' : null}}
                                             onClick={()=>{
                                                 this.setState({
                                                     selectedImage: index
                                                 })
                                             }}
                                             onMouseOver={(e)=>{
                                                 e.target.style.transform = 'scale(1.2)';
                                             }}
                                             onMouseLeave={(e)=>{
                                                 e.target.style.transform = 'scale(1)';
                                             }}
                                        >
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: innerWidth >1250 ? 60 : 30, textAlign: 'left', width: innerWidth >1250 ? '45%': '90%'}}>
                            <div style={{fontFamily: '\'Oswald\', sans-serif', textTransform: 'uppercase', fontSize: 16, fontWeight: 300, letterSpacing: 4,}}>
                                Tjänst
                            </div>
                            <div style={{width: '100%', backgroundColor: 'rgb(255, 225, 27)', marginTop: 9, marginRight: 10, marginBottom: 6, height: 5}}/>

                            <div style={{fontFamily: '\'Ultra\', serif', fontSize: innerWidth >1250 ? 40: 28,marginTop:innerWidth >1250 ? 30:20, textAlign: 'center'}}>
                                {tjanst.title}
                            </div>
                            <div style={{whiteSpace:'pre-wrap',textAlign: innerWidth >1250?'center': 'left', color: 'rgb(25,25,25)', marginTop: innerWidth >1250 ? 30:20, fontFamily: '\'Roboto Slab\', serif', fontSize: innerWidth > 1250 ? 24 : 18, fontWeight: 200,lineHeight: 1.5}}>
                                {tjanst.text}
                            </div>
                        </div>
                    </div>
                    {innerWidth > 1250 ? (
                        <div style={{position: 'relative', width: '100%', marginTop: 20}}>
                            <div className={'hideScroll'} style={{scrollBehavior:'smooth', userSelect: 'none', scrollSnapType: 'x mandatory', scrollSnapStop: 'normal',position: 'relative', display: '-webkit-box', flexDirection: 'row', overflowX: 'overlay', overflowY: 'hidden', width: '100%'}}
                                 ref={this.sliderRef}

                                 onDragEnter={(e)=>{
                                     //e.preventDefault()
                                     //e.stopPropagation()
                                    // console.log(this.sliderRef.current.scrollLeft, e.pageX)
                                     this.sliderRef.current.scrollTo(e.pageX, 0)
                                     this.setState({
                                         dragPos: e.pageX
                                     })
                                 }}
                                 onDragCapture={(e)=>{
                                     //console.log('hej')
                                     /*if(this.state.dragPos - e.pageX > this.state.dragPos) {
                                         this.sliderRef.current.scrollTo(scrollPosition - (this.state.dragPos - e.pageX), 0)
                                     }else if(this.state.dragPos - e.pageX < this.state.dragPos) {
                                         this.sliderRef.current.scrollTo(scrollPosition + (this.state.dragPos - e.pageX), 0)
                                     }*/

                                 }}
                            >
                                {images.map((img, index)=>{
                                    return(
                                        <section key={index}
                                                 style={{scrollSnapAlign: 'start', width: 200, height: 200, margin: 10, backgroundColor:'rgba(255,255,255,1)',/* boxShadow: '0px 0px 11px 1px rgba(0,0,0,0.75)',*/ display: 'flex', alignItems: 'center'}}
                                                 onPointerEnter={(e)=>{
                                                     e.currentTarget.style.transform = 'scale(1.2)';
                                                     //e.currentTarget.style.width = '250px';
                                                 }}
                                                 onPointerLeave={(e)=>{
                                                     e.currentTarget.style.transform = 'scale(1)';
                                                 }}
                                        >
                                            <img src={img}
                                                 draggable={true}
                                                 ondragstart="return false;"
                                                 style={{objectFit: 'contain', width: '100%', height: '100%', userDrag: 'none', pointerEvents: 'auto'}}
                                                 onClick={()=>{
                                                     this.setState({
                                                         selectedImage: index
                                                     })
                                                 }}

                                            />
                                        </section>
                                    );
                                })}

                            </div>
                            {scrollPosition - 200 > 0 ? (
                                <div style={{pointerEvents: 'auto', position: 'absolute', top: 0, left: 0, width: 200, height: '100%', background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(249,249,249,0) 95%)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}
                                     onClick={()=>{
                                         this.sliderRef.current.scrollTo(scrollPosition - 200, 0 );
                                         this.setState({
                                             selectedImage: this.state.selectedImage-1
                                         })
                                     }}
                                >
                                    <motion.div style={{cursor: 'pointer'}}
                                                whileHover={{scale: 1.2}}
                                                whileTap={{scale: 0.9}}

                                    >
                                        <img src={arrow} style={{width: 35,transform:'rotate(90deg)'}}/>
                                    </motion.div>
                                </div>
                            ):null}
                            {scrollPosition - 200 < this.state.sliderWidth ? (
                                <div style={{position: 'absolute', top: 0, right: 0, width: 200, height: '100%', backgroundColor: 'grey', pointerEvents: 'auto', background: 'linear-gradient(90deg, rgba(249,249,249,0) 5%, rgba(255,255,255,1) 100%)', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
                                     onClick={()=>{
                                         this.sliderRef.current.scrollTo(scrollPosition + 200, 0 );
                                         this.setState({
                                             selectedImage: this.state.selectedImage+1
                                         })
                                     }}
                                >
                                    <motion.div style={{cursor: 'pointer'}}
                                                whileHover={{scale: 1.2}}
                                                whileTap={{scale: 0.9}}

                                    >
                                        <img src={arrow} style={{width: 35,transform:'rotate(270deg)'}}/>
                                    </motion.div>
                                </div>
                            ):null}
                        </div>): null}



                </div>
                {this.state.bigImg ? (
                    <div style={{zIndex: 99999, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}
                         onClick={()=>{
                             this.setState({
                                 bigImg: false
                             })
                         }}
                    >
                        <motion.div style={{cursor: 'pointer', padding: 30}}
                                    whileHover={{scale: 1.2}}
                                    whileTap={{scale: 0.9}}
                                    onClick={(event)=>{
                                        event.preventDefault();
                                        event.stopPropagation();
                                        this.sliderRef.current.scrollTo(scrollPosition - 200, 0 );
                                        this.setState({
                                            selectedImage: this.state.selectedImage-1
                                        })
                                    }}
                        >
                            <img src={arrow} style={{width: 35,transform:'rotate(90deg)'}}/>
                        </motion.div>
                        <img src={images[imageIndex]} style={{width: '80%', height: '80%', objectFit: 'contain'}}
                             onClick={(event)=>{
                                 event.preventDefault();
                                 event.stopPropagation();
                             }}
                        />
                        <motion.div style={{cursor: 'pointer', padding: 30}}
                                    whileHover={{scale: 1.2}}
                                    whileTap={{scale: 0.9}}
                                    onClick={(event)=>{
                                        event.preventDefault();
                                        event.stopPropagation();
                                        this.sliderRef.current.scrollTo(scrollPosition + 200, 0 );
                                        this.setState({
                                            selectedImage: this.state.selectedImage+1
                                        })
                                    }}
                        >
                            <img src={arrow} style={{width: 35,transform:'rotate(270deg)'}}/>
                        </motion.div>
                    </div>
                ):null}
            </div>
        );
    }
}

export default Services;
//rgba(235,28,35,1)
