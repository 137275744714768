
module.exports = {
  importFiles: (service) => {
      let r;
      if(service === 'concrete'){
          r = require.context('../../../src/assets/services/concrete', false, /\.(png|jpe?g|svg)$/);
      }else if(service === 'watercrush'){
          r = require.context('../../../src/assets/services/watercrush', false, /\.(png|jpe?g|svg)$/);
      }else if(service === 'spraypaint'){
          r = require.context('../../../src/assets/services/spraypaint', false, /\.(png|jpe?g|svg)$/);
      }else if(service === 'wash'){
          r = require.context('../../../src/assets/services/wash', false, /\.(png|jpe?g|svg)$/);
      }else if(service === 'corrosion'){
          r = require.context('../../../src/assets/services/corrosion', false, /\.(png|jpe?g|svg)$/);
      }else if(service === 'balcony'){
          r = require.context('../../../src/assets/services/balcony', false, /\.(png|jpe?g|svg)$/);
      }else if(service === 'floor'){
          r = require.context('../../../src/assets/services/floor', false, /\.(png|jpe?g|svg)$/);
      }
    let images = [];
        r.keys().forEach((item, index) => {
            images[index] = r(item).default;
        });
        return images;
  }
};
