import React, {Component} from 'react';
import {Redirect} from "react-router";
import about from '../assets/menu/about-us.png';
import improvement from '../assets/menu/improve.png';
import enviroment from '../assets/menu/ecology.png';
import quality from '../assets/menu/winner.png';

const text = {
    about: {
        title: 'Om Nixco',
        bigTitle: 'Välkommen till Nixco',
        text:'NIXCO hjälper dig med balkongrenoveringar, fasadtvätt, takbehandling, sprutmålning, vattenavrinning och industrigolv.  Vi erbjuder våra tjänster till den offentliga sektorn, näringslivet och privatpersoner.\n' +
            '\n' +
            'För oss är det en självklarhet att alltid uppfylla dina krav på kvalitet. Hos oss träffar du därför medarbetare som sätter dig som kund – dina behov, önskemål och förväntningar i fokus.  Medarbetare som arbetar efter att metod och lösning alltid ska ge dig ett fördelaktigt pris och samtidigt vara gynnsamt för miljön. Läs mer om vår kvalitetsgaranti, miljöpolicy och vårt förbättringsprogram.\n' +
            '\n' +
            'För att fortsätta hålla en hög kompetens är vidareutbildning ett viktigt komplement till våra medarbetares mångåriga erfarenhet. Våra medarbetare utbildas därför i:\n' +
            '\n' +
            '• Hantering av två komponenter färgsystem\n' +
            '• Miljöfarligt avfall\n' +
            '• Ultrahöga tryck\n' +
            '• Hjärt- och lungräddning\n' +
            '• Liftkort\n',
        image: about
    },
    environment: {
        title: 'MILJÖPOLICY',
        bigTitle: 'Nixco\'s miljöarbete',
        text: 'Vi på NIXCO värnar om miljön. Därför väljer vi arbetsmetoder som bidrar till bättre miljöförhållande:\n' +
            '\n' +
            '• Minimera spill av material\n' +
            '• Minska påverkan på alla former av jord, luft och vatten, bland annat genom källsortering\n' +
            '• Bedriva verksamheten i samklang med kretsloppstänkande\n' +
            '• Minska användandet av ej förnyelsebara naturtillgångar\n' +
            'Nixco har ett miljöledningssystem enligt ISO 14001:2004.',
        image: enviroment
    },
    improvement: {
        title: 'FÖRBÄTTRINGSPROGRAM',
        bigTitle: 'Nixco blir bättre',
        text: 'För oss är förbättringar och ständig utveckling en naturlig del av allt arbete. Vårt förbättringsprogram är en viktig del i arbetet att utveckla ett bättre erbjudande, en bättre arbetsmiljö och bättre kundrelationer.\n' +
            '\n' +
            'Vi gör en planering inför varje projekt.  Efter genomförandet gör vi en utvärdering av arbetsmetod, kvalitet, miljö, säkerhet och kundrelation- utvärderingen resulterar sen i konkreta åtgärder på förbättringar.',
        image: improvement
    },
    quality: {
        title: 'KVALITETSGARANTI',
        bigTitle: 'Nixco\'s garanti',
        text: 'Vi liksom våra leverantörer arbetar efter ISO standard. Som en del av vår höga kvalitet ger vi rådgivning och hjälp i realtid inom marknadsområdet – vi vill skapa ett bestående mervärde för alla våra kunder.\n' +
            '\n' +
            'Nixco har ett kvalitetsledningssystem enligt ISO 9001:2008.',
        image: quality
    },
};

class About extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        const {mobile} = this.props;
       // console.log(this.props.match)
        let obj = text[this.props.match.params.id];
        if(!obj){
            return (
                <Redirect to={'/'}/>
            )
        }
        if(!mobile) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(100vh - 400px - 50px)',
                    marginTop: 100,
                    alignItems: 'center'
                }}>
                    <div style={{
                        width: '80%',
                        maxWidth: 1500,
                        minHeight: '50vh',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '50%',
                            position: 'relative',
                            marginTop: 0,
                            minHeight: '50vh'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '58%',
                                height: '100%',
                                minHeight: '50vh',
                                background: 'linear-gradient(180deg, rgba(225,255,27,1) 0%, rgba(255,255,255,1) 100%)',
                                alignSelf: 'center', /*boxShadow: '0px 0px 11px 3px rgba(0,0,0,0.75)'*/
                            }}>
                                <img src={obj.image} style={{width: '50%'}}/>
                            </div>

                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'left',
                            width: '50%'
                        }}>
                            <div style={{
                                fontFamily: '\'Oswald\', sans-serif',
                                textTransform: 'uppercase',
                                fontSize: 16,
                                fontWeight: 300,
                                letterSpacing: 4,
                            }}>
                                {obj.title}
                            </div>
                            <div style={{
                                width: '100%',
                                backgroundColor: 'rgb(255, 225, 27)',
                                marginTop: 9,
                                marginRight: 10,
                                marginBottom: 6,
                                height: 5
                            }}/>

                            <div style={{
                                fontFamily: '\'Ultra\', serif',
                                fontSize: 35,
                                marginTop: 20,
                                textAlign: 'center'
                            }}>
                                {obj.bigTitle}
                            </div>
                            <div style={{
                                whiteSpace: 'pre-wrap',
                                textAlign: 'left',
                                color: 'rgb(25,25,25)',
                                marginTop: 20,
                                fontFamily: '\'Roboto Slab\', serif',
                                fontSize: 20,
                                fontWeight: 200,
                                lineHeight: 1.5
                            }}>
                                {obj.text}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return(
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(100vh - 400px - 50px)',
                    marginTop: 100,
                    alignItems: 'center',
                    position: 'relative',
                    marginBottom: 50
                }}>
                    <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '150%',
                                /*background: 'linear-gradient(0deg,  rgba(255,255,27,.6) 0%, rgba(26,25,24,.6) 100%)',*/
                                alignSelf: 'center', /*boxShadow: '0px 0px 11px 3px rgba(0,0,0,0.75)'*/
                                position: 'absolute',
                                top: -100,
                                left: 0,
                                zIndex: -1
                            }}>
                            </div>
                    <div style={{
                        width: '80%',
                        maxWidth: 1500,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'left',
                            width: '100%',
                            position: 'relative'
                        }}>

                            <div style={{
                                fontFamily: '\'Oswald\', sans-serif',
                                textTransform: 'uppercase',
                                fontSize: 16,
                                fontWeight: 300,
                                letterSpacing: 4,
                            }}>
                                {obj.title}
                            </div>
                            <div style={{
                                width: '100%',
                                backgroundColor: 'rgb(255, 225, 27)',
                                marginTop: 9,
                                marginRight: 10,
                                marginBottom: 6,
                                height: 5
                            }}/>

                            <div style={{
                                fontFamily: '\'Ultra\', serif',
                                fontSize: 35,
                                marginTop: 20,
                                textAlign: 'center'
                            }}>
                                {obj.bigTitle}
                            </div>
                            <div style={{
                                whiteSpace: 'pre-wrap',
                                textAlign: 'left',
                                color: 'rgb(25,25,25)',
                                marginTop: 20,
                                fontFamily: '\'Roboto Slab\', serif',
                                fontSize: 20,
                                fontWeight: 200,
                                lineHeight: 1.5,
                            }}>
                                {obj.text}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default About;
