import React, {Component} from 'react';

class NotFound extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '50vh', marginTop: 50}}>
                404 Not Found
            </div>
        );
    }
}

export default NotFound;
